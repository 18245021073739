import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-page-loader',
    templateUrl: './page-loader.component.html',
    styleUrls: ['./page-loader.component.scss']
})
export class PageLoaderComponent implements OnInit {

    @Input() loaderSize = 50;
    @Input() loaderText = '';
    @Input() barWidth = 10;
    @Input() spaceToText = 10;
    @Input() direction = 'row';

    centerCircleSize = 0;
    center = 0;

    constructor() {
    }

    ngOnInit() {
        this.centerCircleSize = this.loaderSize - (this.barWidth * 2);
        this.center = this.loaderSize / 2;
    }

}
