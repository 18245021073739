import {Component, OnInit} from '@angular/core';
import {EventBusService, SystemMessage} from "../../services/event-bus.service";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'message-bar',
    templateUrl: './message-bar.component.html',
    styleUrls: ['./message-bar.component.scss'],
    animations: [
        trigger('show', [
            transition(':enter', [
                style({opacity: 0, 'transform': 'translateY(30px)'}),
                animate('500ms', style({opacity: 1, 'transform': 'translateY(0px)'}))
            ]),
            transition(':leave', [
                style({opacity: 1, 'transform': 'translateY(0px)'}),
                animate('200ms', style({opacity: 0, 'transform': 'translateY(30px)'}))
            ])
        ]),
    ]
})
export class MessageBarComponent implements OnInit {

    constructor(private eventBus: EventBusService) {
    }

    show: boolean = false;
    message: SystemMessage;

    ngOnInit() {

        this.eventBus.getSystemMessageEvent().subscribe(
            (response: SystemMessage) => {
                this.show = true;
                this.message = response;
            }
        )
    }

    showEnd() {
        setInterval(() => {
            this.show = false;
        }, 3000);
    }

}
