/**
 * Created by ericbichara on 2016-11-22.
 */
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from "@angular/router";
import {Subject} from "rxjs/Subject";
import {Observable} from "rxjs/Observable";
import {DataService} from "./data.service";
import {EventBusService} from "./event-bus.service";
import {Injectable} from "@angular/core";

@Injectable()
export class UserAuthGuard implements CanActivate {

    constructor(private dataService: DataService, private eventBus: EventBusService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean| Observable<boolean> {
        return true;
    }
}