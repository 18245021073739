import {
    Component, OnInit, Renderer, ElementRef, EventEmitter, Output, Input,
    OnDestroy
} from '@angular/core';
import {RespService} from "../../services/resp.service";

@Component({
    selector: 'app-add-select',
    templateUrl: 'app-add-select.component.html',
    styleUrls: ['app-add-select.component.scss']
})
export class AddSelectComponent implements OnInit, OnDestroy {


    _options: Array<any>;
    name:string;

    @Output() onSelectChange = new EventEmitter();
    @Output() addNewEvent = new EventEmitter();
    @Output() deleteItemEvent = new EventEmitter();

    @Input('options')
    set options(val: Array<any>) {
        this._options = val;
    }

    optionClicked(option: any) {
        let state: boolean = !option.selected;

        this._options.forEach((op: any) => {
            op.selected = false;
        });

        option.selected = state;
        this.onSelectChange.emit(option);
        this.isVisible = false;
    }

    clickListener: any = null;
    touchListener: any = null;
    isVisible: boolean = false;

    constructor(private element: ElementRef, private renderer: Renderer, private respService: RespService) {
    }

    ngOnInit() {
        this.touchListener = this.renderer.listenGlobal('document', 'touchstart', (event: MouseEvent) => {
            let parentFound = false;
            let check: any = event.target;
            while (check !== null && !parentFound) {
                if (check === this.element.nativeElement) {
                    parentFound = true;
                }
                check = check.parentElement;
            }
            if (!parentFound) {
                this.isVisible = false;
            }
        });

        this.clickListener = this.renderer.listenGlobal('document', 'click', (event: MouseEvent) => {
            let parentFound = false;
            let check: any = event.target;
            while (check !== null && !parentFound) {
                if (check === this.element.nativeElement) {
                    parentFound = true;
                }
                check = check.parentElement;
            }
            if (!parentFound) {
                this.isVisible = false;
            }
        });
    }

    ngOnDestroy(){
        this.clickListener();
        this.touchListener();
    }

    toggleDropdown() {
        this.isVisible = !this.isVisible;
    }

    getTitle() {
        let count = 0;
        let displaySize = this.respService.getSize();
        let text = '';
        if (displaySize === 'xs') {
            text = 'Sortera på';
        }
        if (this._options) {
            for (let i = 0; i < this._options.length; i++) {
                if (this._options[i].selected === true) {
                    if (count === 0) {
                        count = 1;
                        text = this._options[i].friendlyName;
                    } else {
                        count++;
                        text = count + " Valda";
                    }
                }
            }
        }
        return text;
    }

    addNew(){
        if(this.name != ""){
            this.addNewEvent.emit(this.name);
            this.name = "";
            this.isVisible = false;
        }
    }

    deleteItem(id:number){
        this.deleteItemEvent.emit(id);
    }

}
