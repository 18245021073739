import {
    Component, OnInit, Renderer, ElementRef, EventEmitter, Output, Input, forwardRef, HostListener,
    ChangeDetectorRef, ViewEncapsulation, OnDestroy
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-custom-select',
    templateUrl: './custom-select.component.html',
    styleUrls: ['./custom-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomSelectComponent),
            multi: true,
        }]
})
export class CustomSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {

    _options: Array<any>;
    @Input() selectText = 'Sortera på';

    @Output() onSelectChange = new EventEmitter();

    @Input('options')
    set options(val: Array<any>) {
        if (typeof val !== 'undefined' && val != null && val.length !== 0) {
            this._options = val;
            val.forEach((item) => {
                if (item.selected === true) {
                    this.optionClicked(item);
                }
            });
        }
    }

    @Input() width: number;
    @Input() height: number;

    clickListener: any = null;
    touchListener: any = null;
    isVisible = false;
    _disabled: boolean;
    _selectedValue: any;

    /** View -> model callback called when value changes */
    _onChange = (_: any) => {
    };

    /** View -> model callback called when select has been touched */
    _onTouched = () => {
    };

    constructor(private element: ElementRef, private renderer: Renderer, private _changeDetectorRef: ChangeDetectorRef) {
        this._disabled = false;
    }

    // get accessor
    get value(): any {
        return this._selectedValue;
    };

    // set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this._selectedValue) {
            this._selectedValue = v;
            this._onChange(v);
        }
    }

    @HostListener('blur') _onBlur() {
        if (!this.isVisible) {
            this._onTouched();
        }
    }

    ngOnInit() {
        this.touchListener = this.renderer.listenGlobal('document', 'touchstart', (event: MouseEvent) => {
            let parentFound = false;
            let check: any = event.target;
            while (check !== null && !parentFound) {
                if (check === this.element.nativeElement) {
                    parentFound = true;
                }
                check = check.parentElement;
            }
            if (!parentFound) {
                if (this.isVisible) {
                    this._onTouched();
                }
                this.isVisible = false;
            }
        });

        this.clickListener = this.renderer.listenGlobal('document', 'click', (event: MouseEvent) => {
            let parentFound = false;
            let check: any = event.target;
            while (check !== null && !parentFound) {
                if (check === this.element.nativeElement) {
                    parentFound = true;
                }
                check = check.parentElement;
            }
            if (!parentFound) {
                if (this.isVisible) {
                    this._onTouched();
                }
                this.isVisible = false;
            }
        });
    }

    optionClicked(option: any) {

        if (this._selectedValue !== option) {
            this._options.forEach((op: any) => {
                op.selected = false;
            });

            option.selected = true;
            this._selectedValue = option;
            this.selectText = option.friendlyName;
            this._onChange(option);
            this.onSelectChange.emit(this._options);
            this.isVisible = false;
        }
    }

    toggleDropdown() {
        if (this.isVisible) {
            this._onTouched();
        }
        this.isVisible = !this.isVisible;
    }

    /**
     * Sets the select's value. Part of the ControlValueAccessor interface
     * required to integrate with Angular's core forms API.
     *
     * @param value New value to be written to the model.
     */
    public writeValue(obj: any): void {
        if (typeof obj !== 'undefined') {
            this._selectedValue = obj;
        }
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this._disabled = isDisabled;
    }

    ngOnDestroy() {
        this.clickListener();
        this.touchListener();
    }

}
