import {BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpModule, Http} from '@angular/http';
import {FlexLayoutModule} from '@angular/flex-layout';
import {APP_BASE_HREF, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './elements/header/header.component';
import {DataService} from './services/data.service';
import {ModalComponent} from './elements/modal/modal.component';
import {EventBusService} from './services/event-bus.service';
import {LoginPopupComponent} from './elements/login-popup/login-popup.component';
import {MessageBarComponent} from './elements/message-bar/message-bar.component';
import {SideBarComponent} from './elements/side-bar/side-bar.component';
import {UserAuthGuard} from './services/userAuthGuard';
import {TradeinPopupComponent} from './elements/tradein-popup/tradein-popup.component';
import {TranslateModule, TranslateStaticLoader, TranslateLoader} from 'ng2-translate';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {RespService} from './services/resp.service';
import {ElementsModule} from './elements/elements.module';
import {ClientAuthGuard} from './services/clientAuthGuard';

import {ConstantsService} from "./services/constants.service";
import {CreditPopupComponent} from "./elements/credit-popup/credit-popup.component";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AgreePopupComponent} from "./elements/agree-popup/agree.popup.component";
import {GAEventsService} from "./services/ga-events.service";
import {CalcService} from "./services/calc.service";
import {GtmService} from "./services/gtm.service";
import {CookieLawModule} from "angular2-cookie-law";

export class AppHammerConfig extends HammerGestureConfig {
    overrides = <any>{
        'pan': {threshold: 3}
    };
}

export function createTranslateLoader(http: Http) {
    return new TranslateStaticLoader(http, './assets/i18n', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        ModalComponent,
        LoginPopupComponent,
        MessageBarComponent,
        SideBarComponent,
        TradeinPopupComponent,
        CreditPopupComponent,
        NotFoundComponent,
        AgreePopupComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [Http]
        }),
        ElementsModule,
        CookieLawModule
    ],
    providers: [
        DataService,
        EventBusService,
        UserAuthGuard,
        ConstantsService,
        ClientAuthGuard,
        RespService,
        CalcService,
        GAEventsService,
        GtmService,
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: HAMMER_GESTURE_CONFIG, useClass: AppHammerConfig},
        {provide: APP_BASE_HREF, useValue: '!'}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
