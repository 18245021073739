/**
 * Created by ericbichara on 2017-05-22.
 */
import {Injectable} from '@angular/core';

declare let ga:Function;

@Injectable()
export class GAEventsService {

    public emitEvent(
        eventCategory: string,
        eventAction: string,
        eventLabel: string = null,
        eventValue: number = null) {
        ga('send', 'event', {
            eventCategory: eventCategory,
            eventLabel: eventLabel,
            eventAction: eventAction,
            eventValue: eventValue
        });
    }

    public emitPageView(page:string){
        ga('set', 'page', page);
        ga('send', 'pageview');
    }
}