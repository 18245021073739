import {Component, ElementRef, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit, OnDestroy {

    @Input() title: string;
    @Input() content: string;
    show: boolean = false;
    touchListener;
    clickListener;

    constructor(private element: ElementRef, private renderer: Renderer2) {
    }

    ngOnInit() {
    }

    public showTooltip() {
        this.show = !this.show;

        if (this.show === true) {
            this.listenForTouch();
        }else{
            if(this.touchListener && this.clickListener){
                this.touchListener();
                this.clickListener();
            }
        }
    }

    listenForTouch() {
        let self = this;
        setTimeout(() => {
            self.touchListener = self.renderer.listen('document', 'touchstart', (event: MouseEvent) => {
                let parentFound = false;
                let check: any = event.target;
                while (check !== null && !parentFound) {
                    if (check === self.element.nativeElement) {
                        parentFound = true;
                    }
                    check = check.parentElement;
                }
                if (!parentFound) {
                    self.show = false;
                    self.touchListener()
                }
            });

            self.clickListener = self.renderer.listen('document', 'click', (event: MouseEvent) => {
                let parentFound = false;
                let check: any = event.target;
                while (check !== null && !parentFound) {
                    if (check === self.element.nativeElement) {
                        parentFound = true;
                    }
                    check = check.parentElement;
                }
                if (!parentFound) {
                    self.show = false;
                    this.clickListener();
                }
            });
        }, 0);
    }

    ngOnDestroy(){
        if(this.touchListener && this.clickListener){
            this.touchListener();
            this.clickListener();
        }
    }
}
