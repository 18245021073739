import {Component, OnInit, forwardRef, EventEmitter, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR, ControlValueAccessor} from "@angular/forms";

@Component({
    selector: 'app-color-selector',
    templateUrl: './color-selector.component.html',
    styleUrls: ['./color-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ColorSelectorComponent),
            multi: true
        }
    ],
})
export class ColorSelectorComponent implements OnInit, ControlValueAccessor {

    _options:Array<any>;
    @Output() onSelectChange = new EventEmitter();

    writeValue(val: any): void {
        if (val !== undefined) {
            this._options = val;
        }
    }

    propagateChange = (_: any) => {
    };


    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    constructor() {
    }

    ngOnInit() {
    }

    optionClicked(option:any){
        option.selected = !option.selected;
        this.propagateChange(this._options);
        this.onSelectChange.emit(true);
    }

}
