import {Component, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ModalComponent} from "../modal/modal.component";
import {DataService} from "../../services/data.service";
import {Response} from "@angular/http";
import {Router} from "@angular/router";

@Component({
    selector: 'credit-popup',
    templateUrl: 'credit-popup.component.html',
    styleUrls: ['credit-popup.component.scss'],
    animations: [
        trigger('showLogin', [
            state('true', style({height: '100px'})),
            state('false', style({height: '150px'})),
            transition('1 <=> 0', animate('.5s'))
        ]),
        trigger('hideLogin', [
            state('true', style({opacity: 1})),
            state('false', style({opacity: 0})),
            transition('* <=> *', animate('.5s'))
        ])
    ]
})
export class CreditPopupComponent {

    constructor(private dataService: DataService,
                private router: Router) {
    }

    orderId: string;
    showSpinner: boolean = true;
    showSign: boolean = false;
    showError: boolean = false;
    showEmailSentText: boolean = false;
    isCash: boolean = false;
    showOkButton: boolean = false;
    errorText: string = "";
    partnerId: string = "";

    @ViewChild(ModalComponent) modal: ModalComponent;

    show(orderId: any, isCash: boolean, partnerId: string) {
        this.reset();
        this.orderId = orderId;
        this.isCash = isCash;
        this.partnerId = partnerId;

        if (!isCash) {
            this.getCredit();
        } else {
            this.sendApplication();
        }

        this.modal.show();
    }

    reset() {
        this.showSpinner = true;
        this.showSign = false;
        this.showEmailSentText = false;
        this.showOkButton = false;
        this.showError = false;
    }

    getCredit() {
        this.dataService.checkCredit(this.orderId).subscribe(
            (response: Response) => {
                let resp = response.json();
                if (resp.Status === "Order") {
                    this.dataService.sendApplication(this.orderId).subscribe(
                        (sendResponse: Response) => {
                            let resp = sendResponse.json();
                            this.showSpinner = false;
                            if (resp.Status === "AwaitingOrderSignature") {
                              this.showEmailSentText = true;
                              this.showOkButton = true;
                            }
                        },
                        (error: any) => {
                            this.showSpinner = false;
                            this.errorText = error._body.split(",")[1];
                            this.showError = true;
                            this.modal.showCloseButton = true;
                        }
                    );
                } else {

                }
            });
    }

    sendApplication() {
        this.dataService.sendApplication(this.orderId).subscribe(
            (sendResponse: Response) => {
                let resp = sendResponse.json();
                this.showSpinner = false;
                if (resp.Status === "AwaitingOrderSignature") {
                  this.showEmailSentText = true;
                  this.showOkButton = true;
                }
            },
            (error: any) => {
                this.showSpinner = false;
                this.errorText = error._body.split(",")[1];
                this.showError = true;
                this.modal.showCloseButton = true;
            }
        );
  }

    goToCart() {
        this.router.navigate(['/user']);
        this.modal.hide();
    }

    goToStartPage() {
      this.router.navigate(['/'], {queryParams: {'partnerid': this.partnerId}});
      this.modal.hide();
  }

    sign() {
        this.showSpinner = true;
        this.dataService.signOrder(this.orderId).subscribe(
            (response: Response) => {
                this.showSpinner = false;
                let resp = response.json();
                window.open(resp.SignUri);
            }
        )
    }
}



