/**
 * Created by ericbichara on 2017-05-05.
 */
import {AfterViewInit, Directive, DoCheck, ElementRef, EventEmitter, HostListener, Output} from "@angular/core";

export class DIRECTION {
    constructor(hDir: string, vDir: string) {
        this.horizontalPos = hDir;
        this.verticalPos = vDir;
    }

    horizontalPos: string;
    verticalPos: string;
}

@Directive({
    selector: '[menu-scroll]'
})
export class MenuScrollDirective{

    @Output() onScrollChange: EventEmitter<any> = new EventEmitter();
    currentLeftPosition: string = "";
    currentTopPosition: string = "";

    constructor(public el: ElementRef) {
    }

    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
        this.scrollCalc();
    }

    checkScroll(){
        this.scrollCalc();
    }
    
    scrollCalc(){
        //Vertical
        const top = this.el.nativeElement.scrollTop;
        const height = this.el.nativeElement.scrollHeight;
        const ch = this.el.nativeElement.clientHeight;
        const distToBottom = height - (top + ch);

        let topPosition = "";

        if (top === 0) {
            topPosition = "start";
        } else if (distToBottom === 0) {
            topPosition = "end";
        } else {
            topPosition = "middle";
        }

        //Horizontal
        const left = this.el.nativeElement.scrollLeft;
        const width = this.el.nativeElement.scrollWidth;
        const cw = this.el.nativeElement.clientWidth;
        const distToRight = width - (left + cw);

        let leftPosition = "";

        if (left == 0) {
            leftPosition = "start";
        } else if (distToRight === 0) {
            leftPosition = "end";
        } else {
            leftPosition = "middle";
        }

        if (leftPosition !== this.currentLeftPosition || this.currentTopPosition !== topPosition) {
            this.currentLeftPosition = leftPosition;
            this.currentTopPosition = topPosition;
            this.onScrollChange.emit(new DIRECTION(leftPosition, topPosition));
        }
    }
}