import {Injectable} from '@angular/core';
import {EventBusService} from './event-bus.service';

@Injectable()
export class RespService {

    private size: string;

    constructor(private eventBus: EventBusService) {
    }

    setSize(size: number) {
        if (size < 600) {
            this.size = 'xs';
        } else if (size < 960) {
            this.size = 'sm';
        } else if (size < 1280) {
            this.size = 'md';
        } else {
            this.size = 'lg';
        }
        this.eventBus.setRespLayout(this.size);
    }

    getSize() {
        return this.size;
    }

    setMobile(size: string) {
        return (size === 'xs' || size === 'sm');
    }
}
