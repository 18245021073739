import {Component, OnInit, Input} from '@angular/core';

import {DataService} from '../../services/data.service';

@Component({
    selector: 'eb-carousel',
    templateUrl: './eb-carousel.component.html',
    styleUrls: ['./eb-carousel.component.scss']
})
export class EbCarouselComponent implements OnInit {

    url: string = '';
    imageArray: Array<any> = [];
    currentIndex: number = 0;
    currentImage: string = null;

    @Input()
    set images(val: Array<string>) {
        if (val) {
            this.imageArray = val;
            this.setImage(0);
        }
    }

    constructor(private dataService: DataService) {
        this.url = this.dataService.getUrl();
    }

    ngOnInit() {
    }

    setImage(index: number) {
        this.currentIndex = index;
        this.currentImage =  this.url + 'vehicles/images/' + this.imageArray[this.currentIndex];
    }

    previousImage(){
        if (this.currentIndex === 0) {
            this.setImage(this.imageArray.length - 1);
        } else {
            this.setImage(this.currentIndex - 1);
        }
    }

    nextImage(){
        if (this.currentIndex === this.imageArray.length - 1) {
            this.setImage(0);
        } else {
            this.setImage(this.currentIndex + 1);
        }
    }
}
