import {Component, ViewChild, OnInit, HostListener, Inject} from '@angular/core';
import {Router, ActivatedRoute, Data} from '@angular/router';
import {EventBusService} from "./services/event-bus.service";
import {LoginPopupComponent} from "./elements/login-popup/login-popup.component";
import {Subject} from "rxjs/Subject";
import {TradeinPopupComponent, TradeInData} from "./elements/tradein-popup/tradein-popup.component";
import {TranslateService} from "ng2-translate";
import {RespService} from "./services/resp.service";
import {DOCUMENT} from "@angular/common";

import {CreditPopupComponent} from "./elements/credit-popup/credit-popup.component";
import {AgreePopupComponent} from "./elements/agree-popup/agree.popup.component";
import {environment} from "./environment";
import 'rxjs/add/operator/takeUntil';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    @ViewChild(LoginPopupComponent) loginModal: LoginPopupComponent;
    @ViewChild(TradeinPopupComponent) tradeinModal: TradeinPopupComponent;
    @ViewChild(CreditPopupComponent) creditModal: CreditPopupComponent;
    @ViewChild(AgreePopupComponent) agreeModal: AgreePopupComponent;
    @ViewChild('cookieLaw') cookieLawEl: any;

    cookieLawSeen: boolean;
    partnerId: string;

    constructor(private eventBus: EventBusService,
                private translate: TranslateService,
                private respService: RespService,
                private router: Router,
                private route: ActivatedRoute,
                @Inject(DOCUMENT) private document: any) {
        this.respService.setSize(this.getPageWidth());
    }

    ngOnInit(): void {
        // this.cookieLawSeen = this.cookieLawEl.cookieLawSeen;

        this.eventBus.getLoginStartEvent().subscribe(
            (response: Subject<any>) => {
                this.loginModal.show(response);
            }
        );

        this.eventBus.getTradeinEvent().subscribe(
            (response: TradeInData) => {
                this.tradeinModal.show(response);
            }
        );

        this.eventBus.getCreditPopupEvent().subscribe(
            (response: any) => {
                this.creditModal.show(response.orderId, response.isCash, response.partnerId);
            }
        );

        this.eventBus.getAgreeEvent().subscribe(
            (response: any) => {
                this.agreeModal.show(response.subject, response.disclaimer, response.isLowDownPayment, response.isHighDownPayment);
            }
        );

        this.eventBus.getDealerGroupObs().subscribe(
            (response: any) => {
                this.setName(response);
            }
        );

        // this.translate.setDefaultLang("en");
        this.translate.use(environment.lang);

        this.setBranding();
    }

    dismiss(): void {
        this.cookieLawEl.dismiss();
    }

    goToCookies() {
        this.router.navigate(['about-cookies']);
    }

    getParameterByName(name, url) {
        if (!url) { url = window.location.href; }
        name = name.replace(/[\[\]]/g, "\\$&");
        let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) { return null; }
        if (!results[2]) { return ''; }
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    setBranding() {
        const partnerId = this.getParameterByName('partnerid', this.document.location.href);
        const url: string = window.location.hostname;
        const link = this.document.createElement('link');
        let subdomain: string;

        if (partnerId !== '') {
            link.setAttribute('href', 'assets/styles/' + partnerId + '.css');
        } else if (url.split('.').length > 2 && url.split('.')[0] !== 'www') {
            subdomain = url.split('.')[0];
            link.setAttribute('href', 'assets/styles/' + subdomain + '.css');
        }

        // if (url.includes('localhost') || url.includes('dnb-web')) {
        //   link.setAttribute('href', 'assets/styles/light.css');
        // } else {
        //    subdomain = url.split('.')[0];
        //    link.setAttribute('href', 'assets/styles/' + subdomain + '.css');
        //    link.setAttribute('href', 'assets/styles/light.css');
        // }
        /*if(url.includes('dnb-web-dev') || url.includes('slatlem')){
            //link.setAttribute('href', 'assets/styles/default.css');
            link.setAttribute('href', 'assets/styles/light.css');
        } else {
            //link.setAttribute('href', 'assets/styles/light.css');
            link.setAttribute('href', 'assets/styles/default.css');
        }*/

        //
        link.setAttribute('rel', 'stylesheet');
        link.setAttribute('type', 'text/css');
        let head = this.document.getElementsByTagName('head');
        head[0].appendChild(link);
    }

    setName(dealerGroup: any) {
        this.document.title = dealerGroup.name;
    }


    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.respService.setSize(event.target.innerWidth);
    }

    getPageWidth() {
        if (self.innerWidth) {
            return self.innerWidth;
        }

        if (document.documentElement && document.documentElement.clientWidth) {
            return document.documentElement.clientWidth;
        }

        if (document.body) {
            return document.body.clientWidth;
        }
    }
}
