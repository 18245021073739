import {Component, OnInit, ViewChild} from '@angular/core';
import {Response} from '@angular/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {ModalComponent} from '../modal/modal.component';
import {Subject} from 'rxjs';
import {DataService, ErrorMessage} from '../../services/data.service';
import {EventBusService} from '../../services/event-bus.service';
import {animate, state, style, transition, trigger} from "@angular/animations";

export enum LoginPopState{
    Login,
    Register,
    RegisterDone,
    ForgotDone
}

@Component({
    selector: 'login-popup',
    templateUrl: './login-popup.component.html',
    styleUrls: ['./login-popup.component.scss'],
    animations: [
        trigger('showLogin', [
            state('true', style({height: '100px'})),
            state('false', style({height: '150px'})),
            transition('1 <=> 0', animate('.5s'))
        ]),
        trigger('hideLogin', [
            state('true', style({opacity: 1})),
            state('false', style({opacity: 0})),
            transition('* <=> *', animate('.5s'))
        ]),
        trigger('fadeInOut', [
            transition(':enter', [
                style({opacity: 0}),
                animate(300, style({opacity: 1}))
            ]),
            transition(':leave', [
                animate(300, style({opacity: 0}))
            ])
        ])
    ]
})
export class LoginPopupComponent implements OnInit {

    loginForm: FormGroup;
    @ViewChild(ModalComponent) modal: ModalComponent;
    callback: Subject<any>;

    state = true;
    keepMeLoggedIn: boolean;

    loginStates = LoginPopState;
    viewState = LoginPopState.Login;
    title: string;
    text: string;
    showSignInWait: boolean = false;

    showError: boolean;
    errorMessage: string;

    credentials: any = {
        email: '',
        password: ''
    };
    storedCredentials: any = {};
    updateStoredCredentials: boolean = false;

    constructor(private dataService: DataService,
                private eventBus: EventBusService,
                private builder: FormBuilder) {

    }

    ngOnInit() {
        this.setViewState(LoginPopState.Login);

        this.loginForm = this.builder.group({
            'email': [null, Validators.required],
            'password': [null, Validators.required],
        });
        if (typeof localStorage.keepMeLoggedIn !== "undefined") {
            this.keepMeLoggedIn = true;
            this.storedCredentials = JSON.parse(localStorage.keepMeLoggedIn);
            this.credentials.email = this.storedCredentials.username;
            this.credentials.password = this.storedCredentials.password;
        }
    }

    /**
     * Opens the login popup
     * @param {Subject<any>} callback
     */
    show(callback: Subject<any>): void {
        this.showError = false;
        this.errorMessage = "";
        this.callback = callback;
        this.setViewState(LoginPopState.Login);
        this.modal.show();
    }

    /**
     * Sets the title and text of popup according to state
     * @param {LoginPopState} state
     */
    setViewState(state: LoginPopState): void {
        this.viewState = state;
        if (state === LoginPopState.Login) {
            this.title = "LOGIN.HEADER_LOGIN";
            this.text = "LOGIN.LOGIN_TEXT";
        } else if (state === LoginPopState.Register) {
            this.title = "LOGIN.HEADER_REGISTER";
            this.text = "LOGIN.REGISTER_TEXT";
        } else if (state === LoginPopState.RegisterDone) {
            this.title = "LOGIN.REGISTER_DONE";
            this.text = "LOGIN.REGISTER_DONE_TEXT";
        } else if (state === LoginPopState.ForgotDone) {
            this.title = "LOGIN.FORGOT_DONE";
            this.text = "LOGIN.FORGOT_DONE_TEXT";
        }
    }

    /**
     * Stored changes on credential to be remembered.
     * @param form
     */
    checkStoredCredential(form: any) {
        if (this.keepMeLoggedIn) {
            this.updateStoredCredentials = false;
            if (typeof localStorage.keepMeLoggedIn === "undefined") {
                localStorage.setItem("keepMeLoggedIn", "{\"username\":\"" + form.controls.email.value +
                    "\",\"password\":\"" + form.controls.password.value + "\"}");
                this.updateStoredCredentials = true;
            } else if (this.storedCredentials.username != form.controls.email.value
                || this.storedCredentials.password != form.controls.password.value) {
                localStorage.removeItem('keepMeLoggedIn');
                localStorage.setItem("keepMeLoggedIn", "{\"username\":\"" + form.controls.email.value +
                    "\",\"password\":\"" + form.controls.password.value + "\"}");
                this.updateStoredCredentials = true;
            }
            if (this.updateStoredCredentials) {
                this.storedCredentials = {
                    email: form.controls.email.value,
                    password: form.controls.password.value
                };
            }
        } else if (!this.keepMeLoggedIn && typeof localStorage.keepMeLoggedIn !== "undefined") {
            this.storedCredentials = {};
            localStorage.removeItem('keepMeLoggedIn');
        }        
    }
}
