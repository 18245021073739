/**
 * Created by ericbichara on 2017-02-16.
 */
import {CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot} from "@angular/router";
import { Observable} from "rxjs/Observable";
import {DataService} from "./data.service";
import {Injectable} from "@angular/core";

@Injectable()
export class ClientAuthGuard implements CanActivate {

    constructor(private dataService: DataService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean| Observable<boolean> {
        const partnerId = route.queryParams.partnerid;
        let self = this;
        if (this.dataService.checkClient()) {
            return true;
        } else {
            return Observable.create(observer => {
                self.dataService.getClientToken(partnerId).subscribe(
                    (resp: any) => {
                        self.dataService.getDealerGroupMetaData().subscribe(
                            (response: any) => {
                                observer.next(true);
                                observer.complete();
                            },
                            (error: any) => {
                            }
                        )
                    },
                    (error: any) => {
                    }
                )
            });
        }
    }
}