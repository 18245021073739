import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberPipe'
})

export class NumberPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (typeof value !== 'undefined' && value !== null && value !== '') {
            let str: string = value.toString().split('.')[0];
            if (str.length > 3) {
                let newStr = '';
                let count = 0;
                for (let i = str.length - 1; i >= 0; i--) {
                    if (count % 3 === 0 && count !== 0) {
                        newStr = str[i] + ' ' + newStr;
                    }else {
                        newStr = str[i] + newStr;
                    }
                    count++;
                }
                str = newStr;
            }
            return str;
        } else {
            return '';
        }
    }
}
