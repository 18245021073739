import {Component, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {DataService} from "../../services/data.service";
import {ModalComponent} from "../modal/modal.component";
import {EventBusService, SystemMessage} from "../../services/event-bus.service";
import {Response} from "@angular/http";

export class TradeInData{
    constructor(orderId:string, info:any){
        this.orderId = orderId;
        this.info = info;
    }

    orderId:string;
    info:any;
}

@Component({
    selector: 'tradein-popup',
    templateUrl: './tradein-popup.component.html',
    styleUrls: ['./tradein-popup.component.scss'],
    animations: [
        trigger('showLogin', [
            state('true', style({height: '100px'})),
            state('false', style({height: '150px'})),
            transition('1 <=> 0', animate('.5s'))
        ]),
        trigger('hideLogin', [
            state('true', style({opacity: 1})),
            state('false', style({opacity: 0})),
            transition('* <=> *', animate('.5s'))
        ])
    ]

})
export class TradeinPopupComponent implements OnInit {

    constructor(private dataService:DataService, private eventBus:EventBusService) {
    }

    data:TradeInData;
    @ViewChild(ModalComponent) modal: ModalComponent;
    showLogin: boolean = true;

    show(data:TradeInData) {
        this.data = data;
        this.modal.show();
    }

    ngOnInit() {
    }

    sendTradeIn(){
        this.dataService.sendTradein(this.data.orderId, this.data.info).subscribe(
            (response:Response)=>{
                this.modal.hide();
                this.eventBus.sendSystemMessage(new SystemMessage('Trade In', 'Trade In complete'));
            }
        );
    }
}


