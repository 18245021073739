import { Injectable } from '@angular/core';

@Injectable()
export class GtmService {

  constructor() { }

  push(name: string, data?: any) {
    if (data) {
      data.event = name;
        (<any>window).dataLayer.push(data);
        return;
    }

      (<any>window).dataLayer.push({'event': name});
  }
  pushData(value: any) {
      (<any>window).dataLayer.push(value);
  }
}
