import {
    Component, OnInit, forwardRef, Input
} from '@angular/core';
import {RespService} from "../../services/resp.service";
import {NG_VALUE_ACCESSOR, ControlValueAccessor} from "@angular/forms";

@Component({
    selector: 'app-mobile-checkbox',
    templateUrl: './mobile-checkbox.component.html',
    styleUrls: ['./mobile-checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MobileCheckboxComponent),
            multi: true
        }
    ]
})

export class MobileCheckboxComponent implements ControlValueAccessor, OnInit {
    @Input() imgDeselectedPath: string = '';
    @Input() imgSelectedPath: string = '';
    @Input() value: boolean = false;
    @Input() width: number;
    @Input() title: string = '';

    imgPath: string = '';
    displaySize = null;

    constructor(private respService: RespService) {

    }

    writeValue(value: any): void {
        if (value !== undefined) {
            this.value = value;
        }
    }

    propagateChange = (_: any) => {
    };

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    switch() {
        this.value = !this.value;
        if (this.value === true) {
            this.imgPath = this.imgSelectedPath;
        } else {
            this.imgPath = this.imgDeselectedPath;
        }
        this.propagateChange(this.value);
    }

    ngOnInit() {
        this.imgPath = this.imgDeselectedPath;
        this.displaySize = this.respService.getSize();
    }
}
