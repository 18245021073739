import {
    Component, forwardRef, Input
} from '@angular/core';
import {animate, keyframes, style, transition, trigger} from "@angular/animations";
import {NG_VALUE_ACCESSOR, ControlValueAccessor} from "@angular/forms";

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true
        }
    ],
    animations: [
        trigger('selectChange', [
            transition('0 => 1', [
                animate('300ms ease', keyframes([
                    style({'transform': 'scale(1) rotate(0deg)'}),
                    style({'transform': 'scale(1.1) rotate(90deg)'}),
                    style({'transform': 'scale(1.2) rotate(180deg)'}),
                    style({'transform': 'scale(1.1) rotate(270deg)'}),
                    style({'transform': 'scale(1) rotate(360deg)'})
                ]))
            ]),
            transition('1 => 0', [
                animate('300ms ease', keyframes([
                    style({'transform': 'rotate(360deg)'}),
                    style({'transform': 'rotate(270deg)'}),
                    style({'transform': 'rotate(180deg)'}),
                    style({'transform': 'rotate(90deg)'}),
                    style({'transform': 'rotate(0deg)'})
                ]))
            ])
        ])
    ]
})
export class CheckboxComponent implements ControlValueAccessor {

    writeValue(value: any): void {
        if (value !== undefined) {
            this.value = value;
        }
    }

    propagateChange = (_: any) => {
    };

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    @Input() value: boolean = false;

    switch() {
        this.value = !this.value;
        this.propagateChange(this.value);
    }
}
