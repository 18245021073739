import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

export enum errorCodes{
    ORDER_MISSING = 1,
    SEND_APPLICATION_ERROR = 2
}

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

    constructor(private route: ActivatedRoute) {
    }

    errorMessageString:string;

    ngOnInit() {
        const params: any = this.route.params;
        const id = +params.value.id;

        if(id === errorCodes.ORDER_MISSING){
            this.errorMessageString = "ORDER_MISSING";
        }else if(id === errorCodes.SEND_APPLICATION_ERROR){
            this.errorMessageString = "SEND_APPLICATION_ERROR";
        }else{
            this.errorMessageString = "GENERAL_ERROR";
        }
    }
}
