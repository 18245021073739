import {
    Component, forwardRef, OnDestroy, OnInit, ElementRef, Renderer,
    Input, Output, EventEmitter, ViewChild
} from '@angular/core';
import {TranslateService} from 'ng2-translate';
import {NG_VALUE_ACCESSOR, ControlValueAccessor} from "@angular/forms";

@Component({
    selector: 'app-multi-select-submenu-accordion, [app-multi-select-submenu-accordion]',
    inputs: ['isOpen', 'isDisabled'],
    styleUrls: ['multi-select-submenu-accordion.component.scss'],
    templateUrl: 'multi-select-submenu-accordion.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiSelectSubmenuAccordion),
            multi: true
        }
    ]
})
export class MultiSelectSubmenuAccordion implements OnInit, ControlValueAccessor, OnDestroy {

    _options: Array<any> = [];

    @Input() heading: string;
    @Input() width: number = 160;
    @Input() height: number = 30;
    @Output() onSelectChange = new EventEmitter();

    @ViewChild('mainList') mainList: ElementRef;

    isDisabled: boolean;
    _isOpen: boolean = false;

    writeValue(val: any): void {
        if (val !== undefined) {
            this._options = val;
        }
    }

    propagateChange = (_: any) => {
    };

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    /**
     * Returns true if all suboptions are selected and false if some or none are selected
     * @param options
     * @returns {boolean}
     */
    public checkSuboptionValue(options: any): boolean {
        let count = 0;

        for(var o in options) {
            if(options[o].selected) count++;
        }
        return count === options.length;
    }

    /**
     * Toggles sub-options selected or not selected
     * @param options
     */
    public toggleSubOptions(options: any): void {
        let count = 0;

        for (const model of options) {
            if (model.selected === true) {
                count++;
            }
        }
        this.subOptionsSelected = !this.subOptionsSelected;

        this.subOptions.forEach((model: any) => {
            if (this.subOptionsSelected === true) {
                model.selected = true;
            } else {
                model.selected = false;
            }
        });
        this.checkParentSelected();
        this.calculateSelected();
        this.propagateChange(this._options);
        this.onSelectChange.emit(true);
    }

    optionClicked(option: any) {
        option.selected = !option.selected;

        for (let i = 0; i < option.models.length; i++) {
            option.models[i].selected = option.selected;
        }

        this.calculateSelected();

        this.propagateChange(this._options);
        this.onSelectChange.emit(true);
    }

    clickListener: any = null;
    isVisible: boolean = false;
    isSubMenuVisible: boolean = false;
    subOptions: Array<any> = [];
    subOptionTop: string = '0px';
    number: any = 0;
    subOptionsSelected: boolean = false;

    ngOnInit() {
        this.clickListener = this.renderer.listenGlobal('document', 'click', (event: MouseEvent) => {
            let parentFound = false;
            let check: any = event.target;
            while (check !== null && !parentFound) {
                if (check === this.element.nativeElement) {
                    parentFound = true;
                }
                check = check.parentElement;
            }
            if (!parentFound) {
                this.isVisible = false;
                this.isSubMenuVisible = false;
            }
        });
        this.calculateSelected();
    }

    constructor(private element: ElementRef,
                private renderer: Renderer,
                private translate: TranslateService) {
    }

    toggleOpen(event) {
        event.preventDefault();
        if (!this.isDisabled) {
            this.isOpen = !this.isOpen;
        }
    }

    showSubOptions(index: number, option: any) {
        let scroll = this.mainList.nativeElement.scrollTop;
        this.subOptionTop = ((index * 30 + this.height) - scroll) + 'px';
        this.subOptions = option.models;
        this.isSubMenuVisible = true;
    }

    subOptionClicked(subOption: any) {
        subOption.selected = !subOption.selected;
        this.checkParentSelected();
        this.calculateSelected();
        this.propagateChange(this._options);
        this.onSelectChange.emit(true);
    }

    calculateSelected() {
        let currentNumber = 0;
        this._options.forEach((make: any) => {
            make.models.forEach((model: any) => {
                if (model.selected === true) {
                    currentNumber++;
                }
            })
        });
        this.number = currentNumber;
    }

    getTitle() {
        if (this.number === 0) {
            return "";
        } else {
            return this.number + " " + this.translate.instant("SORT_OPTIONS.SELECTED");
        }
    }

    checkParentSelected() {
        this._options.forEach((option: any) => {
            let count = 0;
            option.models.forEach((model) => {
                if (model.selected === true) {
                    count++;
                }
            });

            if (count === option.models.length) {
                option.selected = true;
                option.partialSelect = false;
            } else if (count > 0) {
                option.selected = false;
                option.partialSelect = true;
            } else {
                option.selected = false;
                option.partialSelect = false;
            }
        })
    }

    ngOnDestroy(): void {
        this.clickListener();
    }

    public get isOpen(): boolean {
        return this._isOpen;
    }

    public set isOpen(value: boolean) {
        this._isOpen = value;
    }
}