import {Component, forwardRef, OnDestroy, OnInit, ElementRef, Renderer, Input, Output, EventEmitter} from '@angular/core';
import {TranslateService} from 'ng2-translate';
import {NG_VALUE_ACCESSOR, ControlValueAccessor} from "@angular/forms";

@Component({
    selector: 'app-multi-select-color-accordion, [app-multi-select-color-accordion]',
    inputs: ['heading', 'isOpen', 'isDisabled'],
    styleUrls: ['multi-select-color-accordion.component.scss'],
    templateUrl: 'multi-select-color-accordion.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiSelectColorAccordion),
            multi: true
        }
    ]
})
export class MultiSelectColorAccordion implements OnInit, ControlValueAccessor, OnDestroy {

    _options: Array<any>;

    @Output() onSelectChange = new EventEmitter();
    @Input() heading:string = "";
    @Input('options')
    set options(val:Array<any>) {
        this._options = val;
    }

    isDisabled: boolean;
    _isOpen: boolean = false;

    writeValue(val: any): void {
        if (val !== undefined) {
            this._options = val;
        }
    }

    propagateChange = (_: any) => {
    };

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    optionClicked(option: any) {
        option.selected = !option.selected;
        this.propagateChange(this._options);
        this.onSelectChange.emit(true);
    }

    clickListener: any = null;
    isVisible: boolean = false;

    ngOnInit() {
        this.clickListener = this.renderer.listenGlobal('document', 'click', (event: MouseEvent)=> {
            let parentFound = false;
            let check: any = event.target;
            while (check !== null && !parentFound) {
                if (check === this.element.nativeElement) {
                    parentFound = true;
                }
                check = check.parentElement;
            }
            if (!parentFound) {
                this.isVisible = false;
            }
        });
    }

    constructor(private element: ElementRef,
                private renderer: Renderer,
                private translate: TranslateService) {
    }

    toggleOpen(event) {
        event.preventDefault();
        if (!this.isDisabled) {
            this.isOpen = !this.isOpen;
        }
    }

    getTitle() {
        let count = 0;
        let text = "";
        if (this._options) {
            for (let i = 0; i < this._options.length; i++) {
                if (this._options[i].selected === true) {
                    count++;
                    text = count + " " + this.translate.instant("SORT_OPTIONS.SELECTED");
                }
            }
        }
        return text;
    }

    ngOnDestroy(): void {
        this.clickListener();
    }

    public get isOpen(): boolean { return this._isOpen; }

    public set isOpen(value: boolean) {
        this._isOpen = value;
    }
}