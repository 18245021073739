import {Injectable} from '@angular/core';

@Injectable()
export class ConstantsService {

    public static GUTTER_WIDTH: number = 15;

    public static mod(x: number, n: number): number {
        return ((x % n) + n) % n;
    }

    public static isNullOrEmpty(s: string): boolean {
        if (typeof s !== 'undefined' && s != null && s !== '') {
            return false;
        } else {
            return true;
        }
    }

    calculateCreditCosts(monthlyCost: number, terms: number, monthlyFee: number, loanFee: number, loanCosts: number) {
        let cost:number =((monthlyCost * terms) + (monthlyFee * terms) + loanFee) - loanCosts;
        return cost
    }
/*
    public calculateMonthly(price: number, regFee: number, terms: number,
                            interest: number, rest: number, deposit: number,
                            monthlyFee: number, vatRate: number, type: number,
                            loanFee: number, monthlyAddons: number) {
        //console.log("calc", price, regFee, terms, interest, rest, deposit, monthlyFee, vatRate, type, loanFee, monthlyAddons);
        let loan;
        if (type === 1) {
            let priceWORegWVat = price - regFee;
            let priceWORegWOVat = Math.round(priceWORegWVat / (1 + vatRate / 100));
            let priceWRegWOVat = priceWORegWVat - priceWORegWOVat;//priceWORegWOVat + regFee;
            loan = Math.round(priceWRegWOVat - deposit);

            if (terms < 48) {
                let restTax = ((priceWORegWVat - priceWORegWOVat) / 60) * (48 - terms);
                rest = rest - restTax;
            }
        } else {
            loan = price - deposit + loanFee;
        }
        let i = interest / 100 / 12;

        let monthly = (i * (loan * Math.pow(i + 1, terms) - rest)) /
            ((i * type + 1) * (Math.pow(i + 1, terms) - 1 ));

        monthly = Math.round(monthly); //+ monthlyFee;

        if (type === 1) {
            monthly = Math.round(monthly * (1 + vatRate / 100));
        }

        monthly = monthly + monthlyAddons;

        return monthly;
    }

    calculateEffectiveRate(lanebelopp: number, kontantinsats: number, periodkostnad: number, perioder: number,
                           upplaggningsavgift: number, aviavgift: number, periodsPerAr: number, restvarde: number,
                           addons: number, type: number) {
        // console.log(lanebelopp, kontantinsats, periodkostnad, perioder, upplaggningsavgift, aviavgift, periodsPerAr, restvarde, addons);
        lanebelopp = lanebelopp - kontantinsats;
        periodkostnad = periodkostnad - aviavgift;
        let utbetaln = lanebelopp - upplaggningsavgift;
        let monthlyPayment = periodkostnad + aviavgift - addons;
        let effektivRanta;
        let periodRateS = this.calculatePeriodRate(utbetaln, perioder, monthlyPayment, restvarde, type);
        effektivRanta = (Math.pow(periodRateS + 1, periodsPerAr) - 1) * 100;
        return effektivRanta;
    }

    calculatePeriodRate(utbetlan: number, periods: number, monthlyPayment: number, restvarde: number, type: number) {
        let func0;
        let func1;
        let rate0;
        let rate1 = 0.02;
        let rate2 = 0.03;
        let i = 0;

        if (type == 1) {
            func1 = (((utbetlan - restvarde / Math.pow((1 + rate1), periods)) *
                ((rate1 * Math.pow((1 + rate1), periods)) / (Math.pow((1 + rate1), periods) - 1))) /
                (1 + rate1)) - monthlyPayment;
            do {
                i++;
                rate0 = rate1;
                rate1 = rate2;
                func0 = func1;
                func1 = (((utbetlan - restvarde / Math.pow((1 + rate1), periods)) *
                    ((rate1 * Math.pow((1 + rate1), periods)) / (Math.pow((1 + rate1), periods) - 1))) /
                    (1 + rate1)) - monthlyPayment;
                rate2 = rate1 - func1 * (rate1 - rate0) / (func1 - func0);
            } while (Math.abs(rate2 - rate1) > 0.000001 && i < 60);
        } else {
            func1 = ((utbetlan - restvarde / Math.pow((1 + rate1), periods)) *
                (rate1 * Math.pow((1 + rate1), periods)) /
                (Math.pow((1 + rate1), periods) - 1)) - monthlyPayment;
            do {
                i++;
                rate0 = rate1;
                rate1 = rate2;
                func0 = func1;
                func1 = ((utbetlan - restvarde / Math.pow((1 + rate1), periods)) *
                    (rate1 * Math.pow((1 + rate1), periods)) /
                    (Math.pow((1 + rate1), periods) - 1)) - monthlyPayment;
                rate2 = rate1 - func1 * (rate1 - rate0) / (func1 - func0);
            }
            while (Math.abs(rate2 - rate1) > 0.000001 && i < 60);
        }
        return rate2;
    }

    constructor() {
    }
    */
}
