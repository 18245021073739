/**
 * Created by ericbichara on 2017-01-15.
 */
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { HeartComponent } from './heart/heart.component';
import { ShareComponent } from './share/share.component';
import { EbCarouselComponent } from './eb-carousel/eb-carousel.component';
import { LockComponent } from './lock/lock.component';
import { FooterComponent } from './footer/footer.component';
import { CheckboxComponent } from './checkbox/checkbox.component';

import { MultiSliderComponent } from './multi-slider/multi-slider.component';
import { SliderComponent } from './slider/slider.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { ColorSelectorComponent } from './color-selector/color-selector.component';
import { MultiSelectSubmenuComponent } from './multi-select-submenu/multi-select-submenu.component';
import { SelectComponent } from './select/select.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { CustomSelectComponent } from './custom-select/custom-select.component';
import { FieldErrorDisplayComponent } from './field-error-display/field-error-display.component';
import { BuyButtonComponent } from './buy-button/buy-button.component';
import { NumberPipe } from '../pipes/number.pipe';
import { MonthToYearPipe } from '../pipes/month-to-year.pipe';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import { PageLoaderComponent } from './page-loader/page-loader.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { CapitalizePipe } from '../pipes/capitalize.pipe';
import { TranslateModule } from 'ng2-translate';
import { SplitFirstPipe } from '../pipes/split-first.pipe';
import { AddSelectComponent } from './app-add-select/app-add-select.component';
import { MobileCheckboxComponent } from './mobile-checkbox/mobile-checkbox.component';
import { MultiSelectAccordion } from './accordion/multi-select-accordion.component';
import { SearchTabsComponent } from "./search-tabs/search-tabs.component";
import { SingleSelectAccordion } from "./single-select-accordion/single-select-accordion.component";
import { MultiSelectColorAccordion } from "./multi-select-color-accordion/multi-select-color-accordion.component";
import { MultiSelectSubmenuAccordion } from "./multi-select-submenu-accordion/multi-select-submenu-accordion.component";
import { ActionButtonComponent } from "./action-button/action-button.component";
import { TooltipComponent } from "./tooltip/tooltip.component";
import { MenuScrollDirective } from "../directives/menu-scroll.directive";

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    declarations: [
        HeartComponent,
        ShareComponent,
        EbCarouselComponent,
        LockComponent,
        FooterComponent,
        NumberPipe,
        CheckboxComponent,
        MultiSliderComponent,
        SliderComponent,
        MultiSelectComponent,
        ColorSelectorComponent,
        MultiSelectSubmenuComponent,
        SelectComponent,
        PaginatorComponent,
        RadioGroupComponent,
        ImageCarouselComponent,
        PageLoaderComponent,
        SpinnerComponent,
        CapitalizePipe,
        SplitFirstPipe,
        MonthToYearPipe,
        AddSelectComponent,
        MobileCheckboxComponent,
        MultiSelectAccordion,
        SingleSelectAccordion,
        MultiSelectColorAccordion,
        MultiSelectSubmenuAccordion,
        SearchTabsComponent,
        ActionButtonComponent,
        TooltipComponent,
        MenuScrollDirective,
        CustomSelectComponent,
        BuyButtonComponent,
        FieldErrorDisplayComponent
    ],
    exports: [
        HeartComponent,
        ShareComponent,
        EbCarouselComponent,
        LockComponent,
        FooterComponent,
        NumberPipe,
        CheckboxComponent,
        MultiSliderComponent,
        SliderComponent,
        MultiSelectComponent,
        ColorSelectorComponent,
        MultiSelectSubmenuComponent,
        SelectComponent,
        PaginatorComponent,
        RadioGroupComponent,
        ImageCarouselComponent,
        PageLoaderComponent,
        SpinnerComponent,
        CapitalizePipe,
        SplitFirstPipe,
        MonthToYearPipe,
        AddSelectComponent,
        MobileCheckboxComponent,
        MultiSelectAccordion,
        SingleSelectAccordion,
        MultiSelectColorAccordion,
        MultiSelectSubmenuAccordion,
        SearchTabsComponent,
        ActionButtonComponent,
        TooltipComponent,
        MenuScrollDirective,
        CustomSelectComponent,
        BuyButtonComponent,
        FieldErrorDisplayComponent
    ]
})

export class ElementsModule {
}
