import {
    Component, Input, NgZone, ViewEncapsulation, OnInit, OnDestroy
} from '@angular/core';
import {EventBusService} from '../../services/event-bus.service';
import {RespService} from '../../services/resp.service';
import {Subscription} from 'rxjs';
import {animate, AnimationEvent, state, style, transition, trigger} from "@angular/animations";
import {DataService} from "../../services/data.service";
import {Subject} from "rxjs/Subject";

@Component({
    selector: 'modal-component',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('visibility', [
            state('true', style({top: '10px', opacity: 1})),
            state('false', style({top: '-10px', opacity: 0})),
            transition('* => *', animate('0.3s'))
        ]),
        trigger('topvisibility', [
            state('true', style({opacity: 1})),
            state('false', style({opacity: 0}))
        ])
    ]
})
export class ModalComponent implements OnInit, OnDestroy {

    respObs: Subscription;

    visible = false;
    @Input() title: string;
    @Input() showCloseButton: boolean = true;
    @Input() showImage: boolean = true;

    display = false;
    isMobile = false;
    dealerGroupImage: any;

    constructor(private zone: NgZone,
                private respService: RespService,
                private eventBus: EventBusService,
                private dataService: DataService) {
        this.setSize(this.respService.getSize());
    }

    ngOnInit() {
        this.eventBus.getRespSizeEvent().takeUntil(this.componentDestroyed$).subscribe(
            (response: string) => {
                this.setSize(response);
            }
        );
    }

    getDealerGroup(){
        this.dataService.getDealerGroupMetaData().takeUntil(this.componentDestroyed$).subscribe(
            (response:any)=>{
                this.dealerGroupImage = response.logoUrl;
            }
        )
    }

    private setSize(size: string) {
        if (size === 'xs') {
            this.isMobile = true;
        } else if (size === 'sm') {
            this.isMobile = false;
        } else {
            this.isMobile = false;
        }
    }

    public show() {
        this.display = true;
        this.visible = true;
        this.getDealerGroup();
    }

    public hide() {
        this.visible = false;
    }

    hideModal(event: AnimationEvent) {
        if (event.fromState.toString() === 'true' && event.toState.toString() === 'false') {
            this.zone.run(() => {
                this.display = false;
            });
        }
    }

    componentDestroyed$: Subject<boolean> = new Subject();
    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
