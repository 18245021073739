import {
    Component, OnInit, ElementRef, Input, forwardRef, NgZone, EventEmitter,
    Output
} from '@angular/core';
import {NG_VALUE_ACCESSOR, ControlValueAccessor} from "@angular/forms";

import {EventBusService} from "../../services/event-bus.service";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SliderComponent),
            multi: true
        }
    ],
    animations: [
        trigger('onPan', [
            state('true', style({'transform': 'scale(1.1)', 'box-shadow': '0 0 10px #888888'})),
            transition('*=>*', animate('0.1s'))
        ])
    ]
})
export class SliderComponent implements OnInit, ControlValueAccessor {

    constructor(private elementRef: ElementRef, private zone: NgZone,
                private eventBus: EventBusService) {
        this.eventBus.getRespSizeEvent().subscribe(
            (response: string) => {
                this.setInitValues();
            }
        )
    }

    _max: number = null;
    _min: number = null;
    _value: number = null;

    inputValid: boolean = true;
    emitValue:number;

    @Output() onSelectChange = new EventEmitter();

    @Input()
    set min(val: number) {
        this._min = val;
        this.setInitValues();
    }

    @Input() title: string = "";
    @Input() unit: string = "";
    @Input() showLimits: boolean = false;

    @Input()
    set max(val: number) {
        this._max = val;
        this.setInitValues();
    }

    writeValue(val: any): void {
        if (val !== undefined && val !== null) {
            this._value = val;
            this.emitValue = val;
            this.setInitValues();
        }
    }

    totalWidth: number = 0;
    pointerStart: number = 0;
    span: number = 0;
    handleWidth: number = 20;
    onPan: boolean = false;

    handlePosition: number = 0;

    propagateChange = (_: any) => {
    };


    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setFixedButton(e) {
        var  userAgent = window.navigator.userAgent.toLowerCase();
        var ios = /iphone|ipod|ipad/.test( userAgent );

        if(ios) {
            let elem = document.getElementById('btn-checkout');

            elem.style.position = "absolute";
            elem.style.top = "-55px";
            elem.parentElement.style.zIndex = "10000";
            window.scrollTo(0, 80);
        }
    }

    clearFixedButton() {
        let elem = document.getElementById('btn-checkout');
        elem.style.position = "relative";
        elem.style.top = null;
        window.scrollTo(0, 0);
    }

    ngOnInit() {
        //this.totalWidth = this.elementRef.nativeElement.firstChild.clientWidth;
    }

    setInitValues() {
        this.totalWidth = this.elementRef.nativeElement.firstChild.clientWidth;
        this.span = this._max - this._min;
        if (this._min >= this.emitValue) {
            this.handlePosition = 0;
        } else {
            this.handlePosition = Math.round((this.emitValue - this._min) / (this.span / this.totalWidth));
        }
        if (this.handlePosition > 10) {
            this.handlePosition = this.handlePosition - this.handleWidth;
        }
        this.pointerStart = this.handlePosition;
    }

    pan(event: any) {
        this.inputValid = true;
        this.onPan = true;
        this.zone.run(() => {
            let position = this.pointerStart + event.deltaX;
            if (position <= 0) {
                this.handlePosition = 0;
                this.emitValue = this._value = this._min;
            } else if (position >= this.totalWidth - this.handleWidth) {
                this.handlePosition = this.totalWidth - this.handleWidth;
                this.emitValue = this._value = this._max;
            } else {
                this.handlePosition = position;
                this.emitValue = this._value = Math.floor(this.handlePosition * (this.span / this.totalWidth) + this._min);
            }
            this.propagateChange(this._value);
            this.onSelectChange.emit();
        })
    }

    panEnd() {
        this.onPan = false;
        this.pointerStart = this.handlePosition;
    }

    inputUpdate(val: string) {
        let tempVal = +(val.replace(' ', ''));
        this._value = tempVal;

        if (tempVal >= this._min && tempVal <= this._max) {
            this.propagateChange(this._value);
            this.emitValue = this._value;
            this.inputValid = true;
        } else {
            this.propagateChange(this._min);
            this.emitValue = this._min;
            this.inputValid = false;
        }

        this.setInitValues();
        this.onSelectChange.emit();
    }
}