import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    Renderer,
    forwardRef,
    OnDestroy
} from '@angular/core';
import {TranslateService} from 'ng2-translate';
import {NG_VALUE_ACCESSOR, ControlValueAccessor} from "@angular/forms";

@Component({
    selector: 'app-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiSelectComponent),
            multi: true
        }
    ],
})
export class MultiSelectComponent implements OnInit, ControlValueAccessor, OnDestroy {
    _options: Array<any>;

    @Output() onSelectChange = new EventEmitter();

    @Input() heading: string = "";
    @Input() width: number = 160;
    @Input() height: number = 30;

    writeValue(val: any): void {
        if (val !== undefined) {
            this._options = val;
        }
    }

    propagateChange = (_: any) => {
    };


    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    optionClicked(option: any) {
        option.selected = !option.selected;
        this.propagateChange(this._options);
        this.onSelectChange.emit(true);
    }

    clickListener: any = null;
    touchListener: any = null;
    isVisible: boolean = false;

    constructor(private element: ElementRef,
                private renderer: Renderer,
                private translate: TranslateService) {
    }

    ngOnInit() {

        this.touchListener = this.renderer.listenGlobal('document', 'touchstart', (event: MouseEvent) => {
            let parentFound = false;
            let check: any = event.target;
            while (check !== null && !parentFound) {
                if (check === this.element.nativeElement) {
                    parentFound = true;
                }
                check = check.parentElement;
            }
            if (!parentFound) {
                this.isVisible = false;
            }
        });

        this.clickListener = this.renderer.listenGlobal('document', 'click', (event: MouseEvent) => {
            let parentFound = false;
            let check: any = event.target;
            while (check !== null && !parentFound) {
                if (check === this.element.nativeElement) {
                    parentFound = true;
                }
                check = check.parentElement;
            }
            if (!parentFound) {
                this.isVisible = false;
            }
        });
    }

    ngOnDestroy(): void {
        this.clickListener();
        this.touchListener();
    }

    toggleDropdown() {
        this.isVisible = !this.isVisible;
    }

    checkSelected(): boolean {
        if (this._options) {
            for (let i = 0; i < this._options.length; i++) {
                if (this._options[i].selected === true) {
                    return true
                }
            }
        }
        return false;
    }

    getTitle() {
        let count = 0;
        let text = "";
        if (this._options) {
            for (let i = 0; i < this._options.length; i++) {
                if (this._options[i].selected === true) {
                    if (count === 0) {
                        count = 1;
                        text = this._options[i].friendlyName;
                    } else {
                        count++;
                        text = count + " " + this.translate.instant("SORT_OPTIONS.SELECTED");
                    }
                }
            }
        }

        if(this.heading != ""){
            text = this.heading + ": " + text;
        }
        
        return text;
    }
}
