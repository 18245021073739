import {Injectable} from '@angular/core';
import {Headers, Http, RequestOptions, Response, URLSearchParams} from '@angular/http';
import {Observable} from 'rxjs';
import {EventBusService} from './event-bus.service';
import {environment} from '../environment';

export class ErrorMessage {
    constructor(isError: boolean, errorMessage: string) {
        this.isError = isError;
        this.errorMessage = errorMessage;
    }

    isError: boolean;
    errorMessage: string;
}


@Injectable()
export class DataService {

    private user: any = null;
    private dealerGroupMetadata: any = null;
    private url: any = null;
    private userToken: string = null;

    // Token for the site
    private clientToken: string = null;

    constructor(private http: Http,
                private eventBus: EventBusService) {
        this.url = environment.url;
    }

    /**
     * Gets the meta data about the dealergroup
     * @returns {Observable<any>}
     */
    public getDealerGroupMetaData(): Observable<any> {
        return Observable.create(observer => {
            if (this.dealerGroupMetadata) {
                observer.next(this.dealerGroupMetadata);
                observer.complete();
            } else {
                this.createGet(this.url + 'api/v1/netsales/dealergroup').subscribe(
                    (response: Response) => {
                        this.dealerGroupMetadata = response.json();
                        this.eventBus.setDealerGroup(this.dealerGroupMetadata);
                        observer.next(this.dealerGroupMetadata);
                        observer.complete();
                    },
                    (error: Response) => {
                    }
                );
            }
        });
    }

    /**
     * Restores the metadata to default values
     */
    public clearMetaDataFilter(): void {
        let searchDto = this.dealerGroupMetadata.searchDto;

        searchDto.freeTextSearch = "";
        searchDto.dealerId = 0;

        searchDto.hasLoan = true;
        searchDto.hasLeasing = null;

        // clear makes/models
        searchDto.makeModelList.forEach((make: any) => {
            make.selected = false;
            make.models.forEach((model: any) => {
                model.selected = false;
            })
        });

        // clear options
        searchDto.optionDimensions.forEach((option: any) => {
            if (option.selectType === 'range') {
                option.selectedMaxValue = option.max;
                option.selectedMinValue = option.min;
            }
            option.options.forEach((innerOption: any) => {
                innerOption.selected = false;
            })
        });
    }

    /**
     * Gets the options for the selection of source of money in agreement
     * @returns {Observable<Response>}
     */
    public moneyLaunderingOptions() {
        return this.createGet(this.url + 'api/v1/netsales/dealergroup/moneylaunderingoptions')
    }

    /**
     * Returns a result based on a certain dealer
     * @param query
     * @param {number} page
     * @param {number} itemsPerPage
     * @returns {Observable<Response>}
     */
    public searchByDealerGroup(query: any, page: number, itemsPerPage: number): Observable<Response> {
        return this.createPost(this.url + 'api/v1/netsales/dealergroup/search/' + itemsPerPage +
            '/' + page, query);
    }

    /**
     * Returns details about a certain vehicle
     * @param {number} vehicleId
     * @returns {Observable<Response>}
     */
    getVehicle(vehicleId: number) {
        return this.createGet(this.url + 'api/v1/netsales/vehicles/' + vehicleId);
    }

    /**
     * Returns details about a vehicle basef on a norwegian Finn code
     * @param {string} finnCode
     * @returns {Observable<Response>}
     */
    public getVehicleByFinnCode(finnCode: string): Observable<Response> {
        return this.createGet(this.url + 'api/v1/netsales/vehicles/finncode/' + finnCode);
    }

    /**
     * Saves a vehicle as reserved
     * @param query
     * @returns {Observable<Response>}
     */
    public reserveVehicle(query: any): Observable<Response> {
        return this.createPost(this.url + 'api/v1/netsales/customers/reservation', query);
    }

    /**
     * Gets information about an order from the orderId
     * @param {string} orderId
     * @returns {Observable<Response>}
     */
    public getOrder(orderId: string): Observable<Response> {
        return this.createGet(this.url + 'api/v1/netsales/orders/' + orderId);
    }

    /**
     * Creates an order from a vehicle
     * @param vehicle
     * @returns {Observable<Response>}
     */
    public createOrder(vehicle: any): Observable<Response> {
        return this.createPost(this.url + 'api/v1/netsales/customers/createorder', vehicle);
    }

    /**
     * Sends the application for approval to the API. This is done when you click the Buy button on the checkout page
     * @param {string} orderId
     * @returns {Observable<Response>}
     */
    public sendApplication(orderId: string): Observable<Response> {
        return this.createPost(this.url + 'api/v1/netsales/orders/' + orderId + '/sendapplication/', null);
    }

    /**
     * Sends required trade-in data if present
     * @param {string} orderId
     * @param query
     * @returns {Observable<Response>}
     */
    public sendTradein(orderId: string, query: any): Observable<Response> {
        return this.createPost(this.url + 'api/v1/netsales/orders/' + orderId + '/tradein/', query);
    }

    /**
     * Sign an order
     * @param {string} orderId
     * @returns {Observable<Response>}
     */
    public signOrder(orderId: string): Observable<Response> {
        let webUrl = window.location.origin;
        return this.createPost(this.url + 'api/v1/netsales/orders/' + orderId + '/signorder', {redirectUrl: webUrl + '/#/orderdone/' + orderId});
    }

    /**
     * Gets the status of an application
     * @param {string} orderId
     * @returns {Observable<Response>}
     */
    public checkCredit(orderId: string): Observable<Response> {
        return this.createGet(this.url + 'api/v1/netsales/orders/' + orderId + '/getapplicationstatus/');
    }

    /**
     * Checks valid client token
     * @returns {boolean}
     */
    public checkClient(): boolean {
        return !!this.clientToken;
    }

    /**
     * Gets the client token
     * @returns {Observable<string>}
     */
    public getClientToken(partnerId): Observable<string> {
        let domain = DataService.getDomain(partnerId);
        let self = this;
        return Observable.create(observer => {
            const body = new URLSearchParams();
            body.set('grant_type', 'client_credentials');
            const headers = new Headers();
            headers.append('Authorization', 'Basic ' + btoa(domain));
            headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
            headers.append('Accept', 'q=0.8;application/json;q=0.9');
            self.http.post(this.url + 'token', body, {headers: headers}).subscribe(
                (response: Response) => {
                    self.clientToken = response.json().access_token;
                    observer.next(true);
                    observer.complete();
                },
                (error: any) => {
                    observer.next(false);
                    observer.complete();
                });
        });
    }

    /**
     * fetches a domain string for verification against the API
     * @returns {string}
     */
    static getDomain(partnerId): string {
        let domain: string;
        if (partnerId === '') {
            domain = window.location.hostname; // .replace('www.', '');
        } else {
            domain = 'partnerid=' + partnerId;
        }
        if (domain.includes('herokuapp') || domain.includes('localhost') || domain.includes('172') || domain.includes('127') || domain.includes('labs.')) {
            return "www.onlinecars." + environment.lang + ":"; // default dealergroup
        } else {
            return domain + ":";
        }
    }

    /**
     * Updates user information
     * @param user
     * @returns {Observable<Response>}
     */
    public updateUser(user: any): Observable<any> {
        return this.createPost(this.url + 'api/v1/netsales/customers/update', user);
    }

    /**
     * Requests a newsletter subscription
     * @param {string} email
     * @returns {Observable<Response>}
     */
    public activateNewsLetter(email: string): Observable<Response> {
        return this.createPost(this.url + "api/v1/netsales/dealergroup/newsletter", {email: email, active: true});
    }

    /**
     * Returns all selections for citizenship from Solutio
     * @returns {Observable<Response>}
     */
    public getSolutioData(): Observable<Response> {
        return this.createGet(this.url + 'api/v1/netsales/orders/getSolutioLookupOptions');
    }

    /**
     * Deletes an order
     * @param {string} orderId
     * @returns {Observable<Response>}
     */
    public deleteOrder(orderId: string): Observable<Response> {
        return this.createDelete(this.url + 'api/v1/netsales/orders/' + orderId);
    }

    /**
     * Saves an order
     * @param order
     * @returns {Observable<Response>}
     */
    public saveOrder(order: any): Observable<Response> {
        return this.createPost(this.url + '/api/v1/netsales/orders/saveorder/', order);
    }

    /**
     * Gets an order by orderId
     * @param {string} orderId
     * @returns {Observable<Response>}
     */
    getOrderSummary(orderId: string): Observable<Response> {
        return this.createGet(this.url + 'api/v1/netsales/orders/' + orderId + '/summary');
    }

    /**
     * Returns the current url
     * @returns {string}
     */
    public getUrl(): string {
        return this.url;
    }

    /**
     * Adds no-cache to Cache-Control and Pragma header to prevent IE to cache json
     * @param {Headers} headers
     */
    addNoCache(headers: Headers): void {
        headers.append('Cache-Control', 'no-cache');
        headers.append('Pragma', 'no-cache');
    }

    /**
     * Gets address information from SSN
     * @param {string} ssn
     * @returns {Observable<Response>}
     */
    getUserInfoBySSN1(ssn: string, zip: string): Observable<Response> {
        return this.createGet(this.url + 'api/v1/netsales/customers/lookup/?ssn=' + ssn+ '&zip=' + zip);
    }

    /**
     * Gets address information from SSN
     * @param {string} ssn
     * @param {string} zip
     * @returns {Observable<any>}
     */
    getUserInfoBySSN(ssn: string, zip: string, customerId: string): Observable<any> {
        let self = this;
        return Observable.create(observer => {
            this.createGet(this.url + 'api/v1/netsales/customers/lookup/?ssn=' + ssn+ '&zip=' + zip + '&customerId=' + customerId)
                .subscribe(
                    (response: any) => {
                        observer.next(new ErrorMessage(false, response._body));
                        observer.complete();
                    },
                    (error: any) => {
                        observer.next(new ErrorMessage(true, error._body.split(",")[1]));
                    }
                )
        })
    }

    /* Utils */
    /**
     * Create a /POST http-request with an authorization header
     * @param {string} fullUrl
     * @param query
     * @returns {Observable<Response>}
     */
    private createPost(fullUrl: string, query: any): Observable<Response> {
        const body: string = JSON.stringify(query);
        const headers = new Headers({'Content-Type': 'application/json', 'Accept': 'q=0.8;application/json;q=0.9'});
        headers.append("Authorization", "Bearer " + this.clientToken);
        this.addNoCache(headers);
        const options = new RequestOptions({headers: headers});
        return this.http.post(fullUrl, body, options);
    }

    /**
     * Create a /DELETE http-request with an authorization header
     * @param {string} fullUrl
     * @returns {Observable<Response>}
     */
    private createDelete(fullUrl: string): Observable<Response> {
        const headers = new Headers({'Content-Type': 'application/json', 'Accept': 'q=0.8;application/json;q=0.9'});
        headers.append("Authorization", "Bearer " + this.clientToken);
        this.addNoCache(headers);
        const options = new RequestOptions({headers: headers});
        return this.http.delete(fullUrl, options);
    }

    /**
     * Create a /GET http-request with an authorization header
     * @param {string} fullUrl
     * @returns {Observable<Response>}
     */
    private createGet(fullUrl: string): Observable<Response> {
        const headers = new Headers({'Accept': 'q=0.8;application/json;q=0.9'});
        headers.append("Authorization", "Bearer " + this.clientToken);
        this.addNoCache(headers);
        const options = new RequestOptions({headers: headers});
        return this.http.get(fullUrl, options);
    }
}
