import {
    Component, OnInit, ElementRef, EventEmitter, Output, Input,
    OnDestroy, Renderer2
} from '@angular/core';
import {RespService} from "../../services/resp.service";

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit, OnDestroy {


    _options: Array<any>;

    @Output() onSelectChange = new EventEmitter();

    @Input('options')
    set options(val: Array<any>) {
        this._options = val;
    }

    @Input() width: number;
    @Input() height: number;
    @Input() selectText: any = "Sorter Etter";
    @Input() colorType: any = "dark";
    @Input() disabled:boolean = false;
    @Input() showTitle:boolean = false;

    optionClicked(option: any) {
        let state: boolean = !option.selected;

        this._options.forEach((op: any) => {
            op.selected = false;
        });

        option.selected = state;
        this.onSelectChange.emit(this._options);
        this.isVisible = false;
    }

    clickListener: any = null;
    touchListener: any = null;
    isVisible: boolean = false;
    displaySize = null;

    constructor(private element: ElementRef, private renderer: Renderer2, private respService: RespService) {
    }

    ngOnInit() {
        this.displaySize = this.respService.getSize();

        this.touchListener = this.renderer.listen('document', 'touchstart', (event: MouseEvent) => {
            let parentFound = false;
            let check: any = event.target;
            while (check !== null && !parentFound) {
                if (check === this.element.nativeElement) {
                    parentFound = true;
                }
                check = check.parentElement;
            }
            if (!parentFound) {
                this.isVisible = false;
            }
        });

        this.clickListener = this.renderer.listen('document', 'click', (event: MouseEvent) => {
            let parentFound = false;
            let check: any = event.target;
            while (check !== null && !parentFound) {
                if (check === this.element.nativeElement) {
                    parentFound = true;
                }
                check = check.parentElement;
            }
            if (!parentFound) {
                this.isVisible = false;
            }
        });
    }

    ngOnDestroy() {
        this.clickListener();
        this.touchListener();
    }

    toggleDropdown() {
        this.isVisible = !this.isVisible;
    }

    getTitle() {
        let count = 0;
        let displaySize = this.respService.getSize();
        let text = '';
        //if (displaySize === 'xs') {
        text = this.selectText;
        //}
        if (this._options) {
            for (let i = 0; i < this._options.length; i++) {
                if (this._options[i].selected === true) {
                    if (count === 0) {
                        count = 1;
                        if(this.showTitle){
                            text = this.selectText + ": " + this._options[i].friendlyName;;
                        }else{
                            text = this._options[i].friendlyName;
                        }
                    } else {
                        count++;
                        if(this.showTitle){
                            text = this.selectText + ": " + count + " Valda";
                        }else{
                            text = count + " Valda";
                        }
                    }
                }
            }
        }

        return text;
    }

}
