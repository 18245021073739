import {Component, OnInit, Input, Output, EventEmitter, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-radio-group',
    templateUrl: './radio-group.component.html',
    styleUrls: ['./radio-group.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioGroupComponent),
            multi: true
        }
    ],
})
export class RadioGroupComponent implements OnInit, ControlValueAccessor {

    _options: Array<any>;
    @Output() onSelectChange = new EventEmitter();
    @Input() valueType: string;
    @Input() radioValueKey = 'duration';

    writeValue(val: any): void {
        if (val !== undefined) {
            this._options = val;
        }
    }

    propagateChange = (_: any) => {
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    constructor() {
    }

    ngOnInit() {
    }

    calcValue(val: number) {
        return val;
    }

    optionClicked(option: any) {
        this._options.forEach((radio: any) => {
            radio.selected = false;
        });

        option.selected = true;
        this.propagateChange(this._options);
        this.onSelectChange.emit(option);
    }

}
