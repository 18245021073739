import {Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnChanges {
    @Input() paginationOptions: any;
    @Input() totalResults: number;

    @Output() pageChange = new EventEmitter();

    start:number = 0;
    end:number = 0;

    ngOnChanges(changes: SimpleChanges): void {
        if (this.totalResults) {
            this.initialize();
        }
    }

    buttons: Array<any> = [];

    constructor() {
    }

    ngOnInit() {

    }

    buttonClick(page:number) {
        this.paginationOptions.currentPage = page;
        this.pageChange.emit(page);
        this.initialize();
    }

    initialize() {
        this.buttons = [];
        let numButtons = Math.ceil(this.totalResults / this.paginationOptions.resultsPerPage);
        for (let i = 0; i < numButtons; i++) {
            this.buttons.push({page: i + 1});
        }

        this.start = (this.paginationOptions.currentPage - 1) * this.paginationOptions.resultsPerPage + 1;
        if(this.totalResults - this.start < this.paginationOptions.resultsPerPage){
            this.end = this.totalResults;
        }else{
            this.end = this.start + this.paginationOptions.resultsPerPage;
        }
    }

}
