import {Component, OnInit, OnDestroy, Input, ElementRef} from '@angular/core';
import {DataService} from '../../services/data.service';
import {ConstantsService} from '../../services/constants.service';
import {EventBusService} from '../../services/event-bus.service';
import {RespService} from '../../services/resp.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
    selector: 'app-image-carousel',
    templateUrl: './image-carousel.component.html',
    styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent implements OnInit, OnDestroy {

    respObs: Subscription;
    url = '';
    imageArray: Array<any> = [];
    imageURLArray: Array<string> = [];
    imageCount: number = 0;
    currentIndex = 0;
    elemWidth = 0;
    elemHeight = 0;
    isSwitching = false;
    isLoading = true;
    indexArray: Array<number> = [];
    numberOfThumbImagesShown = 0;
    currentThumbPageIndex = 0;
    thumbWidth = 80;
    atCarouselEndpoints = false;
    isMobile = null;
    domAdapter = null;

    constructor(private dataService: DataService,
                private elem: ElementRef,
                private eventBus: EventBusService,
                private respService: RespService) {

        this.url = dataService.getUrl();
    }

    ngOnInit() {
        this.setSize(this.respService.getSize());

        this.respObs = this.eventBus.getRespSizeEvent().subscribe((response: string) => {
            this.setSize(response, true);
        });
    }

    @Input()
    set images(val: Array<string>) {
        if (val) {
            this.imageArray = val;
            this.setImage(0);
            this.currentThumbPageIndex = 0;
            this.numberOfThumbImagesShown = Math.floor(this.elemWidth / this.thumbWidth);
            // Ugly way of waiting until image is set in DOM to get the actual height
            /*setTimeout(() => {
             this.elemHeight = this.elem.nativeElement.offsetHeight;
             }, 500);*/
            this.imageCount = this.imageArray.length;
        }
    }

    @Input() showThumbnails: boolean = true;

    setSize(size: string, isResize = false) {
        if (size === 'xs') {
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }
        this.calculateDimensions(isResize);
    }

    calculateDimensions(isResize: boolean) {
        if (!this.isMobile && !isResize) {
            this.elemWidth = this.elem.nativeElement.offsetWidth - (ConstantsService.GUTTER_WIDTH / 2);
        } else {
            this.elemWidth = this.elem.nativeElement.offsetWidth;
        }
        this.elemHeight = this.elemWidth / 1.5;
    }

    calculateHeight(ratio: number) {
        return this.elemWidth / ratio;
    }

    setImage(index: number) {
        this.currentIndex = index;
        for (let i: number = index - 2; i < index + 2; i++) {
            const actualIndex = ConstantsService.mod(i, this.imageArray.length);
            if (ConstantsService.isNullOrEmpty(this.imageURLArray[actualIndex])) {

                if (this.imageArray.length > (actualIndex - 1)) {
                    this.imageURLArray[actualIndex] = this.url + 'api/v1/netsales/vehicles/images/' + this.imageArray[actualIndex];
                }

            }
        }
        this.isLoading = false;
    }

    getImageURL(index: number) {
        const i = ConstantsService.mod(index, this.imageURLArray.length);
        return this.imageURLArray[i];
    }

    getFirstImageURL() {
        return this.url + 'api/v1/netsales/vehicles/images/' + this.imageArray[0];
    }

    getLastImageURL() {
        return this.url + 'api/v1/netsales/vehicles/images/' + this.imageArray[this.imageArray.length - 1];
    }

    getVehicleThumbImage(str: string) {
        return this.url + 'api/v1/netsales/vehicles/images/' + str + '?maxwidth=320&maxheight=0';
    }

    currentLeftPos() {
        return (this.currentIndex + 1) * this.elemWidth * -1;
    }

    currentThumbLeftPos() {
        if (((this.currentThumbPageIndex + 1) * this.numberOfThumbImagesShown) < this.imageArray.length) {
            return this.currentThumbPageIndex * this.numberOfThumbImagesShown * this.thumbWidth * -1;
        } else {
            return ((this.thumbWidth * (this.imageArray.length)) - this.elemWidth) * -1;
        }
    }

    checkIsSwitching(): boolean {
        if (this.isSwitching) {
            return true;
        } else {
            this.isSwitching = true;
            // Wait 100ms more than the CSS animation that is 500ms at the point of writing this comment
            setTimeout(() => {
                this.isSwitching = false;
            }, 600);
            return false; // So that it is actually setting the image the first time
        }
    }

    switchImage(steps: number, noAnimation = false) {
        if (!this.checkIsSwitching() && steps !== 0) {
            const nextIndex = this.currentIndex + steps;
            const newIndex = ConstantsService.mod(nextIndex, this.imageArray.length);
            /*
             handle array endpoints differently so that it does the slide animation
             and then moves the index to the last or first item without an animation
             */
            if (nextIndex === -1 || nextIndex === this.imageArray.length || noAnimation) {
                this.setImage(nextIndex);

                // Timeout to wait for the CSS animation, set to 550ms, set boolean so that no animation is done.
                setTimeout(() => {
                    this.atCarouselEndpoints = true;
                    this.currentIndex = newIndex;

                    // Wait 50ms and activate animations again.
                    setTimeout(() => {
                        this.atCarouselEndpoints = false;
                    }, 50);
                }, 550);
            } else {
                this.setImage(newIndex);
            }
        }
    }

    switchDirectlyToImageIndex(index: number) {
        if (!this.checkIsSwitching()) {
            this.atCarouselEndpoints = true;
            this.setImage(index);
            // Wait 50ms and activate animations again.
            setTimeout(() => {
                this.atCarouselEndpoints = false;
            }, 50);
        }
    }

    switchThumbImages(steps: number) {
        if (!this.checkIsSwitching()
            && steps !== 0
            && !(this.currentThumbPageIndex === 0 && steps < 0)
            && !(((this.currentThumbPageIndex + 1) * this.numberOfThumbImagesShown) > this.imageArray.length && steps > 0)) {
            this.currentThumbPageIndex += steps;
        }
    }

    openImage() {
        var win = window.open(this.imageURLArray[this.currentIndex], '_blank');
        win.focus();
    }

    ngOnDestroy(): void {
        if (this.respObs) {
            this.respObs.unsubscribe();
        }
    }
}
