import {Component, Input} from '@angular/core';

@Component({
    selector: 'eb-lock',
    templateUrl: './lock.component.html',
    styleUrls: ['./lock.component.scss']
})
export class LockComponent {

    @Input() value: boolean = false;
}
