import {
    Component, OnInit, ElementRef, Input, forwardRef, NgZone, Output,
    EventEmitter, AfterViewInit
} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {NG_VALUE_ACCESSOR, ControlValueAccessor} from "@angular/forms";
import {EventBusService} from "../../services/event-bus.service";

@Component({
    selector: 'app-multi-slider',
    templateUrl: './multi-slider.component.html',
    styleUrls: ['./multi-slider.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiSliderComponent),
            multi: true
        }
    ],
    animations: [
        trigger('onPan', [
            state('true', style({'transform': 'scale(1.1)', 'box-shadow': '0 0 10px #888888'})),
            transition('*=>*', animate('0.1s'))
        ])
    ]
})
export class MultiSliderComponent implements OnInit, ControlValueAccessor, AfterViewInit {

    constructor(private elementRef: ElementRef,
                private zone: NgZone,
                private eventBus: EventBusService) {
        this.eventBus.getRespSizeEvent().subscribe(
            (response: string) => {
                this.initComp();
            }
        )
    }

    totalWidth: number = 0;
    position: number = 0;
    _option: any = null;
    span: number = 0;
    handleWidth: number = 20;

    onLeftPan: boolean = false;
    leftPointerStart: number = 0;
    leftPosition: number = 0;

    onRightPan: boolean = false;
    rightPointerStart: number = 0;
    rightPosition: number = 0;

    emitMin: number;
    emitMax: number;
    minInput: number;
    maxInput: number;
    minInputValid: boolean = true;
    maxInputValid: boolean = true;

    @Output() onSelectChange = new EventEmitter();
    @Input() title: string = '';
    @Input() unit: string = '';
    @Input() showLimits: boolean = false;

    @Input('option')
    set option(val: any) {
        if (val) {
            this._option = val;
            this.minInput = this.emitMin = this._option.selectedMinValue;
            this.maxInput = this.emitMax = this._option.selectedMaxValue;
            this.initComp();
        }
    }

    writeValue(val: any): void {
        if (val !== undefined) {
            this._option = val;
        }
    }

    propagateChange = (_: any) => {
    };

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        let self = this;
        setTimeout(() => {
            self.initComp();
        }, 0);
    }

    initComp() {
        this.totalWidth = this.elementRef.nativeElement.children[0].clientWidth;
        this.position = this.elementRef.nativeElement.getBoundingClientRect().left;
        if (this._option) {
            this.setInitValues();
        }
    }

    setInitValues() {
        this.span = this._option.max - this._option.min;
        if (this.minInput === this._option.min) {
            this.leftPosition = 0;
        } else {
            this.leftPosition = Math.round((this._option.selectedMinValue - this._option.min) / (this.span / this.totalWidth));
        }

        if (this.maxInput === this._option.max) {
            this.rightPosition = this.totalWidth - this.handleWidth;
        } else {
            this.rightPosition = Math.round((this._option.selectedMaxValue - this._option.min) / (this.span / this.totalWidth));
        }

        this.leftPointerStart = this.leftPosition;
        this.rightPointerStart = this.rightPosition;
    }

    pan(event: any, isLeft: boolean) {
        this.zone.run(() => {
            if (isLeft === true) {
                this.onLeftPan = true;
                let position = this.leftPointerStart + event.deltaX;
                if (position <= 0) {
                    this.leftPosition = 0;
                    this.minInput = this._option.min;
                } else if (position >= this.rightPosition - this.handleWidth) {
                    this.leftPosition = this.rightPosition - this.handleWidth;
                    this.minInput = Math.floor(this.leftPosition * (this.span / this.totalWidth) + this._option.min);
                } else {
                    this.leftPosition = position;
                    this.minInput = Math.floor(this.leftPosition * (this.span / this.totalWidth) + this._option.min);
                }
            } else {
                this.onRightPan = true;
                let position = this.rightPointerStart + event.deltaX;
                if (position > this.totalWidth - this.handleWidth) {
                    this.rightPosition = this.totalWidth - this.handleWidth;
                    this.maxInput = this._option.max;
                } else if (position <= this.leftPosition + this.handleWidth) {
                    this.rightPosition = this.leftPosition + this.handleWidth;
                    this.maxInput = Math.floor(this.rightPosition * (this.span / this.totalWidth) + this._option.min);
                } else {
                    this.rightPosition = position;
                    this.maxInput = Math.floor(this.rightPosition * (this.span / this.totalWidth) + this._option.min);
                }
            }
        });
    }

    panEnd() {
        this._option.selectedMaxValue = this.emitMax = this.maxInput;
        this._option.selectedMinValue = this.emitMin = this.minInput;
        this.onLeftPan = false;
        this.onRightPan = false;
        this.propagateChange(true);
        this.onSelectChange.emit();
        this.leftPointerStart = this.leftPosition;
        this.rightPointerStart = this.rightPosition;
    }

    inputUpdate(val: string, isMin: boolean) {
        let tempVal = +(val.replace(' ', ''));
        if (isMin) {
            this.minInput = tempVal;
            if (tempVal >= this._option.min && tempVal < this.maxInput) {
                this._option.selectedMinValue = tempVal;
                this.minInputValid = true;
            } else {
                this._option.selectedMinValue = this._option.min;
                this.minInputValid = false;
            }
        } else if (!isMin) {
            this.maxInput = tempVal;
            if (tempVal <= this._option.max && tempVal > this.minInput) {
                this._option.selectedMaxValue = tempVal;
                this.maxInputValid = true;
            } else {
                this._option.selectedMaxValue = this._option.max;
                this.maxInputValid = false;
            }
        }

        this.propagateChange(true);
        this.setInitValues();
        this.onSelectChange.emit();
    }
}
