import {Component, OnInit} from '@angular/core';
import {NavigationStart, Router} from "@angular/router";
import {ConstantsService} from '../../services/constants.service';
import {DataService} from "../../services/data.service";
import {Response} from "@angular/http";


@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    gutterWidth: string = ConstantsService.GUTTER_WIDTH + 'px';
    showFooter: boolean = true;
    email:string = "";
    dealerGroup: any;

    constructor(public router: Router,
        private dataService: DataService) {
        
        this.router.events.subscribe(
            (event: any) => {
                if (event instanceof NavigationStart && event.url.includes("orderdone")) {
                    this.showFooter = false;
                } else {
                    this.showFooter = true;
                }
            }
        );

    }
    
    ngOnInit() {
        this.dataService.getDealerGroupMetaData().subscribe(
            (dealerGroup: any) => {
                this.dealerGroup = dealerGroup;
            }
        );
    }

    goToPage(page: string) {
        this.router.navigate([page]);
    }

    activateNewsletter() {
        if(this.email !== ""){
            this.dataService.activateNewsLetter(this.email).subscribe(
                (response:Response)=>{
                    //done
                }
            );
        }
    }
}
