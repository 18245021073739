/**
 * Created by ericbichara on 2017-05-23.
 */

import {Injectable} from '@angular/core';

export enum LoanType{
    LEASING = 1,
    LOAN = 0
}

/*
 oneTimeFee = registrationFee
 entryFee = estFee = etableringsgebyr = establishmentFee
 docFee = documentFee = tinglysningsgebyr
 monthlyFee = termFee = AdminFee = termingebyr
 terms = periods
 deposit = equity
 */

@Injectable()
export class CalcService {

    public calculateMonthlyPrice(monthlyInterest: number, terms: number, residual: number, price: number, type: LoanType,
                                 oneTimeFee: number, vat: number, deposit: number, entryFee: number, documentFee: number,
                                 monthlyFee: number) {

        residual = this.configureResidual(residual, price, terms, type, vat, oneTimeFee);
        let payedLoan = this.calculateLoanSum(deposit, price, type, entryFee, documentFee, vat, oneTimeFee);
        monthlyInterest = monthlyInterest / 100 / 12;

        let monthlyPayment = (monthlyInterest * (payedLoan * Math.pow(monthlyInterest + 1, terms) - residual))
            / ((monthlyInterest * type + 1) * (Math.pow(monthlyInterest + 1, terms) - 1));

        monthlyPayment += monthlyFee;
        if (type === LoanType.LEASING) {
            monthlyPayment = monthlyPayment * (1 + vat / 100);
        }

        return Math.round(monthlyPayment);
    }

    private configureResidual(residual: number, price: number, terms: number, type: LoanType, vat: number, oneTimeFee: number): number {
        if (type === LoanType.LOAN || vat === 0) {
            //do nothing
        } else {
            let vatVal = (1 + vat / 100 );

            let priceVatNoOneTimeFee = price - oneTimeFee;
            let priceNoVatNoOneTimeFee = priceVatNoOneTimeFee / vatVal;
            let vatAmount = priceVatNoOneTimeFee - priceNoVatNoOneTimeFee;

            let restTax = 0;
            if (terms < 48) {
                restTax = (vatAmount / 60) * (48 - terms);
                residual = residual - restTax;
            }
        }

        return residual;
    }

    private calculateLoanSum(deposit: number, price: number, type: LoanType, entryFee: number, documentFee: number, vat: number,
                             oneTimeFee: number) {
        let loanAmount = 0;

        if (type === LoanType.LOAN) {
            loanAmount = this.configureLoanAmount(price, deposit, entryFee, documentFee);
        } else {
            loanAmount = this.configureLeasingAmount(price, deposit, vat, oneTimeFee);
        }

        return loanAmount;
    }

    private configureLoanAmount(price: number, deposit: number, entryFee: number, documentFee: number) {
        return price - deposit + entryFee + documentFee;
    }

    private configureLeasingAmount(price: number, deposit: number, vat: number, oneTimeFee: number) {
        let priceVatNoOTF = price - oneTimeFee;
        vat = 1 + vat / 100;
        let priceNoVatNoOTF = priceVatNoOTF / vat;
        let priceNoVatOTF = priceNoVatNoOTF + oneTimeFee;
        let depositNoVat = deposit / vat;

        return priceNoVatOTF - depositNoVat;
    }

    public calculateEffectiveRate(monthlyInterest: number, terms: number, residual: number, price: number, type: LoanType,
                                  oneTimeFee: number, vat: number, deposit: number, entryFee: number, documentFee: number,
                                  monthlyFee: number) {

        let payedLoan = price - deposit;
        let nominalMonthlyPayment = this.calculateMonthlyPrice(monthlyInterest, terms, residual, price, type,
            oneTimeFee, vat, deposit, entryFee, documentFee, monthlyFee);


        let func0 = 0;
        let func1 = 0;
        let rate0 = 0;
        let rate1 = 0.02;
        let rate2 = 0.03;
        let i = 0;

        func1 = ((payedLoan - residual / Math.pow((1 + rate1), terms)) *
            (rate1 * Math.pow((1 + rate1), terms)) /
            (Math.pow((1 + rate1), terms) - 1)) - nominalMonthlyPayment;

        do {
            i++;
            rate0 = rate1;
            rate1 = rate2;
            func0 = func1;
            func1 = ((payedLoan - residual / Math.pow((1 + rate1), terms)) *
                (rate1 * Math.pow((1 + rate1), terms)) /
                (Math.pow((1 + rate1), terms) - 1)) - nominalMonthlyPayment;
            rate2 = rate1 - func1 * (rate1 - rate0) / (func1 - func0);
        } while (Math.abs((rate2 - rate1)) > 0.000001 && i < 60);

        let rate: number = ((Math.pow((1 + rate2), 12) - 1)) * 100;
        return rate.toFixed(2);
    }

}