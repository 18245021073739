import {Component, OnInit} from '@angular/core';
import {EventBusService} from "../../services/event-bus.service";
import {DataService} from "../../services/data.service";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss'],
    animations: [
        trigger('show', [
            transition(':enter', [
                style({'transform': 'translateX(100%)'}),
                animate('200ms', style({'transform': 'translateX(0px)'}))
            ]),
            transition(':leave', [
                style({'transform': 'translateX(0px)'}),
                animate('100ms', style({'transform': 'translateX(100%)'}))
            ])
        ]),
    ]
})
export class SideBarComponent implements OnInit {

    constructor(private eventBus: EventBusService,
                private dataService: DataService) {
    }

    ngOnInit() {
        this.eventBus.getSideBarOpenEvent().subscribe(
            (response: any) => {
                this.visible = !this.visible;
            }
        );
    }

    visible: boolean = false;
    loggedin: boolean = false;

    toggleSideBar() {
        this.visible = !this.visible;
    }
}