import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {UserAuthGuard} from './services/userAuthGuard';
import {ClientAuthGuard} from './services/clientAuthGuard';

const routes: Routes = [
    {path: '', loadChildren: './pages/dsearch/dsearch.module#DsearchModule', pathMatch: 'full', canActivate:[ClientAuthGuard]},
    {path: 'campaign/:campaignId', loadChildren: './pages/dsearch/dsearch.module#DsearchModule', pathMatch: 'full', canActivate: [ClientAuthGuard]},
    {path: 'vehicle', loadChildren: './pages/vehicle/vehicle.module#VehicleModule', pathMatch: 'full', canActivate: [ClientAuthGuard]},
    {path: 'vehicle/:id', loadChildren: './pages/vehicle/vehicle.module#VehicleModule', pathMatch: 'full', canActivate: [ClientAuthGuard]},
    {path: 'finncode/:id', loadChildren: './pages/vehicle/vehicle.module#VehicleModule', pathMatch: 'full', canActivate: [ClientAuthGuard]},
    {path: 'order/:id', loadChildren: './pages/vehicle/vehicle.module#VehicleModule', pathMatch: 'full', canActivate: [ClientAuthGuard, UserAuthGuard]},
    {path: 'checkout/:id', loadChildren: './pages/checkout/checkout.module#CheckoutModule', pathMatch: 'full', canActivate: [ClientAuthGuard, UserAuthGuard]},
    {path: 'orderdone/:id', loadChildren: './pages/order-done/order-done.module#OrderDoneModule', pathMatch: 'full', canActivate: [ClientAuthGuard]},
    {path: 'user', loadChildren: './pages/user/user.module#UserModule', pathMatch: 'full', canActivate: [ClientAuthGuard, UserAuthGuard]},
    {path: 'activate/:token', loadChildren: './pages/activate/activate.module#ActivateModule', pathMatch: 'full', canActivate:[ClientAuthGuard]},
    {path: 'error/:id', component: NotFoundComponent, pathMatch: 'full'},
    {path: '**', component: NotFoundComponent, pathMatch: 'full'},
];

@NgModule({
    // imports: [RouterModule.forRoot(routes)],
    imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
    exports: [RouterModule],
    providers: [
    ]
})

export class AppRoutingModule {
}