import {Component, OnInit, Inject} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from "@angular/router";
import {EventBusService} from "../../services/event-bus.service";
import {Subject} from "rxjs/Subject";
import {DataService} from "../../services/data.service";
import {DOCUMENT} from "@angular/platform-browser";
import {TranslateService} from 'ng2-translate';
import {TestService} from "../../services/test.service";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    constructor(private router: Router,
                private eventBus: EventBusService,
                private dataService: DataService,
                private translate: TranslateService) {
        this.eventBus.getDealerGroupObs().subscribe(
            (dealerGroup: any) => {
                this.dealerGroup = dealerGroup;
                this.translate.use(this.dealerGroup.countryCode.toLowerCase());
            }
        );

        this.router.events.subscribe(
            (event: any) => {
                if (event instanceof NavigationEnd && event.url.includes("orderdone")) {
                    this.showHeader = false;
                } else {
                    this.showHeader = true;
                }
            }
        )

    }

    dealerGroup: any;
    loggedin: boolean = false;
    firstName: string = "";
    showHeader: boolean = true;
    showLogout: boolean = false;

    ngOnInit() {
    }

    goToDSearch() {
        this.router.navigate(['dsearch']);
    }

    goToStart(url: string) {
        if(url.includes("autogruppen") || url === "") {
            this.router.navigate(['start']);
        } else {
            if(!url.includes("http:")) {
                url = "http://" + url;
            }
            window.location.href = url;
        }
    }

    openSideBar() {
        this.eventBus.openSideBar();
    }

    clearMetaDataFilter() {
        this.dataService.clearMetaDataFilter();
    }
}