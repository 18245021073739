import {Component, OnInit, EventEmitter, Output, Input} from '@angular/core';

@Component({
  selector: 'app-buy-button',
  templateUrl: './buy-button.component.html',
  styleUrls: ['./buy-button.component.scss']
})
export class BuyButtonComponent implements OnInit {

  @Output() sendApplicationEvent = new EventEmitter();

  @Input() payment: number;
  @Input() buttonText: string
  @Input() isMonthlyPayment: boolean;

  constructor() { }

  ngOnInit() {
  }

  sendApplication() {
      this.sendApplicationEvent.emit();
  }
}
