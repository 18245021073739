//NOT USED, see environment.ts file under src/app

//NO PROD
export const environment = {url: 'https://wholesale.dnbfinans.no/netsale/', environment: 'prod', lang: 'no'};
//NO TEST
//export const environment = {url: 'https://stest.wholesale.dnbfinans.no/netsale/', environment: 'test', lang: 'no'};
//NO DEV
//export const environment = {url: 'https://wholesale-no-dev.azurewebsites.net/', environment: 'dev', lang: 'no'};

//SE PROD
//export const environment = {url: '', environment: 'prod', lang: 'se'};
//SE TEST
//export const environment = {url: '', environment: 'test', lang: 'se'};
//SE DEV
//export const environment = {url: 'https://wholesale-se.azurewebsites.net/', environment: 'dev', lang: 'se'};