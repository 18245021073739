import {
    Component, forwardRef, OnDestroy, OnInit, ElementRef,
    Renderer, Input, Output, EventEmitter
} from '@angular/core';
import {NG_VALUE_ACCESSOR, ControlValueAccessor} from "@angular/forms";

@Component({
    selector: 'app-single-select-accordion, [app-single-select-accordion]',
    inputs: ['heading', 'isOpen', 'isDisabled'],
    styleUrls: ['single-select-accordion.component.scss'],
    templateUrl: 'single-select-accordion.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SingleSelectAccordion),
            multi: true
        }
    ]
})
export class SingleSelectAccordion implements OnInit, ControlValueAccessor, OnDestroy {

    _options: Array<any>;

    @Output() onSelectChange = new EventEmitter();
    @Input() heading: string = "";

    @Input('options')
    set options(val: Array<any>) {
        this._options = val;
    }

    isDisabled: boolean;
    _isOpen: boolean = false;

    writeValue(val: any): void {
        if (val !== undefined) {
            this._options = val;
        }
    }

    propagateChange = (_: any) => {
    };

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    optionClicked(option: any) {
        let state: boolean = !option.selected;

        this._options.forEach((op: any) => {
            op.selected = false;
        });

        option.selected = state;
        this.onSelectChange.emit(this._options);
        this.isVisible = false;
        this.isOpen = !this.isOpen;
    }

    clickListener: any = null;
    isVisible: boolean = false;

    constructor(private element: ElementRef, private renderer: Renderer) {
    }

    ngOnInit() {
        this.clickListener = this.renderer.listenGlobal('document', 'click', (event: MouseEvent) => {
            let parentFound = false;
            let check: any = event.target;
            while (check !== null && !parentFound) {
                if (check === this.element.nativeElement) {
                    parentFound = true;
                }
                check = check.parentElement;
            }
            if (!parentFound) {
                this.isVisible = false;
            }
        });
    }

    getTitle() {
        let text = "";
        if (this._options) {
            for (let i = 0; i < this._options.length; i++) {
                if (this._options[i].selected === true) {
                    text = this._options[i].friendlyName;
                }
            }
        }
        return text;
    }

    toggleOpen(event) {
        event.preventDefault();
        if (!this.isDisabled) {
            this.isOpen = !this.isOpen;
        }
    }

    ngOnDestroy(): void {
        this.clickListener();
    }

    public get isOpen(): boolean {
        return this._isOpen;
    }

    public set isOpen(value: boolean) {
        this._isOpen = value;
    }
}