/**
 * Created by ericbichara on 2017-05-11.
 */
import {Component, ViewChild} from '@angular/core';
import {Response} from '@angular/http';
import {ModalComponent} from "../modal/modal.component";
import { animate, state, style, transition, trigger } from "@angular/animations";
import {Subject} from "rxjs/Subject";
import {DIRECTION, MenuScrollDirective} from "../../directives/menu-scroll.directive";
import { DataService } from '../../services/data.service';

@Component({
    selector: 'agree-popup',
    templateUrl: 'agree-popup.component.html',
    styleUrls: ['agree-popup.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate(300, style({ opacity: 1 }))
            ]),
            transition(':leave', [
                animate(300, style({ opacity: 0 }))
            ])
        ]),
        trigger('shrinkInOut', [
            state('in', style({ height: '*' })),
            transition('* => void', [
                style({ height: '*' }),
                animate('500ms cubic-bezier(0.645, 0.045, 0.355, 1.000)', style({ height: 0 }))
            ]),
            transition('void => *', [
                style({ height: 0 }),
                animate('500ms cubic-bezier(0.645, 0.045, 0.355, 1.000)', style({ height: '*' }))
            ])
        ])],
})
export class AgreePopupComponent {

    callback;
    downPaymentOrigin: any = {};
    downPaymentOriginText: string = '';
    downPaymentOriginValues: any;// = {options:[{code:'x', friendlyName:'en test', selected:false},{code:'y', friendlyName:'en annan test', selected:false},{code:'OTHER', friendlyName:'Ange...', selected:false} ]};
    private FREETEXT_OPTION: string = 'OTHER';
    showFreetext: boolean = false;
    allowAgree: boolean = true;
    disclaimer: string = "";
    disclaimerTitle: string = "";
    approve: string = '';
    isLowDownPayment: boolean = false;
    isHighDownPayment: boolean = true;

    componentDestroyed$: Subject<boolean> = new Subject();

    constructor(private dataService: DataService) {
        this.downPaymentOrigin.selected = '';
    }

    @ViewChild(ModalComponent) modal: ModalComponent;
    @ViewChild(MenuScrollDirective) scrollDir:MenuScrollDirective;

    show(callback: Subject<any>, disclaimer: string, isLowDownPayment: boolean, isHighDownPayment: boolean) {
        this.dataService.moneyLaunderingOptions()
            .subscribe((response: Response) => {
                this.downPaymentOriginValues = response.json();
            });
        this.callback = callback;
        this.disclaimer = disclaimer;
        this.approve = '';

        if (isLowDownPayment) {this.approve = 'LowDownPayment'}
        if (isHighDownPayment) {this.approve = 'HighDownPayment'}

        if (disclaimer === 'checkout') {
            this.disclaimerTitle = "CHECKOUT.AGREEMENT_TITLE";
        } else if (disclaimer === 'cashAmount') {
            this.disclaimerTitle = "VEHICLE.AGREEMENT_TITLE";
        }

        this.modal.show();
        //this.scrollDir.checkScroll();
    }


    change(param: string, options: Array<any>) {
        options.forEach((option) => {
            if (option.selected === true) {
                // console.log('selected ', param, option.code, option.friendlyName);
                this.downPaymentOrigin = option;
                this.showFreetext = (option.code === this.FREETEXT_OPTION);
            }
        });
    }

    getHighDownPaymentText() {
        if(this.approve === 'HighDownPayment') {
            var dpo = this.downPaymentOrigin.friendlyName;
            if(this.downPaymentOrigin.code === this.FREETEXT_OPTION) {
                dpo = this.downPaymentOriginText;
            }
            return { text: dpo, code: this.downPaymentOrigin.code};
        }
        return { text: '', code: ''};
    }

    public isErrorControl(controlName: string) {
        if(this.approve !== 'HighDownPayment') {
            return false;
        }
        switch (controlName) {
            case 'downPaymentOrigin':
                return (this.downPaymentOrigin === undefined || !this.downPaymentOrigin.selected || this.downPaymentOrigin.friendlyName.length === 0);

            case 'downPaymentOriginText':
                return (this.showFreetext && this.downPaymentOriginText.length === 0);

            default:
                break;
        }
    }

    reset() {
    }

    hide() {
        this.modal.hide();
    }

    isValidForConfirm(){
        return this.allowAgree && !(this.isErrorControl('downPaymentOrigin') || this.isErrorControl('downPaymentOriginText'));
    }

    onScrollChange(event: DIRECTION) {
        /*if (event.verticalPos === 'end') {
            this.allowAgree = true;
        } else {
            this.allowAgree = false;
        }*/
    }

    answer(answer: boolean) {
        if (answer === true) {
            if (!this.isValidForConfirm()) return;
            this.callback.next({ value: 'confirm', downPaymentOrigin: this.getHighDownPaymentText() });
        }
        this.modal.hide();

    }

}



