import {Component, OnInit, OnDestroy, ElementRef, Renderer, Input} from '@angular/core';
import {TranslateService} from 'ng2-translate';

@Component({
    selector: 'app-share',
    templateUrl: './share.component.html',
    styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit, OnDestroy {

    isVisible: boolean = false;
    clickListener: any = null;
    touchListerner: any = null;
    @Input() vehicleId: number;
    @Input() partnerId: string;

    constructor(private element: ElementRef, private renderer: Renderer, private translate: TranslateService) {
    }

    ngOnInit() {
        this.clickListener = this.renderer.listenGlobal('document', 'click', (event: MouseEvent) => {
            if (!this.element.nativeElement.contains(event.target)) {
                this.isVisible = false;
            }
        });
        this.touchListerner = this.renderer.listenGlobal('document', 'touchstart', (event: MouseEvent) => {
            if (!this.element.nativeElement.contains(event.target)) {
                this.isVisible = false;
            }
        });
    }

    toggleShareContainer() {
        let subject = this.translate.instant('SHARE.MAIL_SUBJECT');
        let body = this.translate.instant('SHARE.BODY_1') + location.protocol + "//" + window.location.host + "/%23!/vehicle/" + this.vehicleId + "/?partnerid=" + this.partnerId + this.translate.instant('SHARE.BODY_2');
        window.open("mailto:?subject=" + subject + "&body=" + body, "_self");
    }

    ngOnDestroy() {
        this.clickListener();
        this.touchListerner();
    }
}
