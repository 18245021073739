import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-tabs',
  templateUrl: 'search-tabs.component.html',
  styleUrls: ['search-tabs.component.scss']
})
export class SearchTabsComponent implements OnInit {

  selectedTab: number;
  isMobile: boolean = false;
  _options: Array<any>;

  @Output() onClickItem = new EventEmitter();
  @Output() onInitSelectTab = new EventEmitter();

  @Input('options')
  set options(val: Array<any>) {
    this._options = val;
  }

  constructor() { }

  ngOnInit() {
    this.selectedTab = this._options[0].id;
    this.onInitSelectTab.emit(this.selectedTab);
  }

  setTab(tab: number) {
    window.scrollTo(0, 0);
    this.selectedTab = tab;
    this.onClickItem.emit(tab);
  }
}
