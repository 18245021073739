import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'splitFirst'
})

export class SplitFirstPipe implements PipeTransform {
    transform(value: string, separator: string, position: number): string {
        if (value !== null && separator) {
            let splitsArray: any [] = value.split(separator);
            if (splitsArray.length > 1) {
                return splitsArray[position];
            } else {
                return value;
            }
        }
        return value;
    }
}
