import {Injectable} from '@angular/core';
import {Subject} from "rxjs/Subject";
import {TradeInData} from "../elements/tradein-popup/tradein-popup.component";

export class SystemMessage {
    constructor(title: string, text: string) {
        this.text = text;
        this.title = title;
    }

    title: string;
    text: string;
}

@Injectable()
export class EventBusService {

    private loginStartObs: Subject<any> = new Subject<any>();
    private systemMessageObs: Subject<SystemMessage> = new Subject<SystemMessage>();
    private sideBarOpenObs: Subject<any> = new Subject<any>();
    private dealerGroupObs: Subject<any> = new Subject<any>();
    private loginUpdateObs: Subject<boolean> = new Subject<boolean>();
    private tradeinObs: Subject<TradeInData> = new Subject<TradeInData>();
    private respObs: Subject<string> = new Subject<string>();
    private creditPopupObs: Subject<any> = new Subject<any>();
    private agreePopupObs: Subject<any> = new Subject<any>();

    constructor() {
    }

    /* Call these events to initiate action */

    /**
     * Initiate login, will bring up login window and call callback with done
     * @param callback
     */
    login(callback: Subject<any>) {
        this.loginStartObs.next(callback);
    }

    /**
     * Initiate Trade in popup
     * @param query
     */
    tradeIn(query: any) {
        this.tradeinObs.next(query);
    }

    openCreditPopup(orderId: any, isCash: boolean, partnerId: string) {
        this.creditPopupObs.next({orderId, isCash, partnerId});
    }

    /**
     * Call this to inform all objects listening to
     * loginUpdateObs that there is a new login state
     * @param val, true = logged in, false = logged out
     */
    loginUpdate(val: boolean) {
        this.loginUpdateObs.next(true);
    }

    agreeStart(callback: Subject<any>, disclaimer: string, isLowDownPayment: boolean, isHighDownPayment: boolean) {
        this.agreePopupObs.next({subject:callback, disclaimer:disclaimer, isLowDownPayment:isLowDownPayment, isHighDownPayment:isHighDownPayment});
    }

    /**
     * Opens side bar
     */
    openSideBar() {
        this.sideBarOpenObs.next(true);
    }

    /**
     * Creates system message, shown at the bottom of the page
     * @param message
     */
    sendSystemMessage(message: SystemMessage) {
        this.systemMessageObs.next(message);
    }

    /**
     * Set dealer group for application
     * @param dealerGroup
     */
    setDealerGroup(dealerGroup: any) {
        this.dealerGroupObs.next(dealerGroup);
    }

    /**
     * Set layout size xs, sm, md, lg
     * @param size
     */
    setRespLayout(size: string) {
        this.respObs.next(size);
    }

    /* Observables */

    /**
     * Listen to this object in order to get logged in state changes
     * @returns {Subject<boolean>}
     */
    getLoginUpdateEvent() {
        return this.loginUpdateObs;
    }

    /**
     * Listen to this object to be notified when someone is trying to login
     * @returns {Subject<any>}
     */
    getLoginStartEvent() {
        return this.loginStartObs;
    }

    /**
     * Listen to this object to be notified of status messages
     * @returns {Subject<SystemMessage>}
     */
    getSystemMessageEvent() {
        return this.systemMessageObs;
    }

    /**
     * Listen to this object to be notified of open sidenav requests
     * @returns {Subject<any>}
     */
    getSideBarOpenEvent() {
        return this.sideBarOpenObs;
    }

    /**
     * Listen to this object to be notified of tradein requests
     * @returns {Subject<TradeInData>}
     */
    getTradeinEvent() {
        return this.tradeinObs;
    }

    /**
     * List to this object to be notified of agree request
     * @returns {Subject<any>}
     */
    getAgreeEvent() {
        return this.agreePopupObs;
    }

    /**
     * Listen to this object to be notified of dealgroup changes
     * @returns {Subject<DealerGroup>}
     */
    getDealerGroupObs() {
        return this.dealerGroupObs;
    }

    /**
     * Listen to this object to be notified of size changes
     * @returns {Subject<string>}
     */
    getRespSizeEvent() {
        return this.respObs;
    }

    /**
     * Listen to this object to be notified of credit popup request
     * @returns {Subject<any>}
     */
    getCreditPopupEvent() {
        return this.creditPopupObs;
    }


}